import step1 from "../../../assets/images/aboutus/shield-image.png";

// styles
import "./styles.scss";

const AppannieSection = (props) => {
  return (
    <div className="appannie-parent">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-sm-6">
            <div className="contentbox">
              <div className="title">
              Global Recognition
              </div>

              <div className="info-section">
                <div className="gradient-title">
                  APP ANNIE | #Levelup
                </div>
                <div className="title-md">Top publisher awards 2021</div>
                <div className="description">
                  Top 10 India headquartered
                  Applications publishers
                </div>
              </div>
              <div className="number-section">
                <div className="box">
                  <div className="no-txt">550M+</div>
                  <div className="title-text">Users Engaged</div>
                </div>
                <div className="box">
                  <div className="no-txt">1Bn+</div>
                  <div className="title-text">Monthly Minutes</div>
                </div>
                {/* <div className="box">
                  <div className="no-txt">100+</div>
                  <div className="title-text">Countires Reached</div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="imagebox">
              <img src={step1} alt="Latest milestone" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppannieSection;
