import { Link } from "react-router-dom";
import React from "react";

import HeroSec from "../../components/pressandmedia/hero";
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import AppyHighEcosystem from "../../components/pressandmedia/AppyhighEcosystem";
// Styles
import "./styles.scss";
import MediaDetails from "../../components/pressandmedia/MediaDetails";
import AllProductLogos from "../../components/pressandmedia/AllProductLogos";
import BrandColors from "../../components/pressandmedia/BrandColors";
import PressRelease from "../../components/pressandmedia/PressRelease";

const PressAndMedia = () => {
  return (
    <div>
      <div className="">
        <Navbar />
      </div>
      <div className="">
        <HeroSec />
      </div>
      <div className="">
        <MediaDetails />
      </div>
      <div className="">
        <AppyHighEcosystem />
      </div>

      <div className="">
        <AllProductLogos />
      </div>

      <div className="">
        <BrandColors />
      </div>

      <div className="">
        <PressRelease />
      </div>

      <div className="">
        <Footer />
      </div>
    </div>
  );
};

export default PressAndMedia;
