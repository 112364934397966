import React, { useEffect, useState } from "react";
import HelmetCustom from "../../components/helmetCustom";
// sections
import HeroSec from "../../components/careers/hero";
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import OpenPositions from "../../components/careers/openPositions";
import OurCulture from "../../components/careers/ourCulture";
import JoinUs from "../../components/joinUs";
import position from './positions.json'
// Styles
import "./careers.scss";

// array of items for Open position section


const Careers = () => {
  let value = position.openpositionsName;
  let filteredopening = position.openpositionsName;
  const [dataopening, setState] = React.useState(value);
  const [eventclass, setclass] = React.useState('');
  const nextactive = (event, elementclass) => {
    setclass(elementclass);
    var tagbutton = document.getElementsByClassName("tagbutton");
    filteredopening = [];
    for (var i = 0; i < tagbutton.length; i++) {
      tagbutton[i].classList.remove("active");
      if (tagbutton[i].classList[1] === elementclass) {
        tagbutton[i].classList.add("active");
        value.filter((item, i) => {
          if (elementclass === item.tag && elementclass !== 'all') {
            filteredopening.push(item);
            setState(filteredopening)
          }
          if (elementclass === 'all') {
            setState(value)
          }
        });
      }
    }
    var txtclass1Elements = document.getElementsByClassName("link-section");
    setTimeout(() => {
      for (var i = 0; i < txtclass1Elements.length; i++) {
        txtclass1Elements[i].style.display = "none";
        if (txtclass1Elements[i].classList[1] == elementclass) {
          txtclass1Elements[i].style.display = "block";
        }
        if (elementclass === "all") {
          txtclass1Elements[i].style.display = "block";
        }
      }
    }, 600);
  };
  return (
    <>
      <HelmetCustom title="Careers" metaDescription="Imagine a world where living is simple, decluttered and enriching. We are creating that reality, one app at a time!" />
      <div className="careers-root">
        <Navbar />

        <div className="">
          <HeroSec />
        </div>

        <div className="openings_parent" id="openingsParent">
          <div>
            <div className="title">CURRENT OPENINGS</div>
            <div className="subtitle">
              See yourself here? There’s always room for more.
            </div>
            <div className="tabButtonSec">
              <div
                className="tagbutton all active"
                {...nextactive}
                onClick={() => {
                  nextactive(null, "all");
                }}
              >
                All
              </div>
              <div
                className="tagbutton Data_AI"
                {...nextactive}
                onClick={() => {
                  nextactive(null, "Data_AI");
                }}
              >
                Data/ AI
              </div>
              <div
                className="tagbutton Finance"
                {...nextactive}
                onClick={() => {
                  nextactive(null, "Finance");
                }}
              >
                Finance
              </div>
              <div
                className="tagbutton Graphic_Design"
                {...nextactive}
                onClick={() => {
                  nextactive(null, "Graphic_Design");
                }}
              >
                Graphic Design
              </div>
              <div
                className="tagbutton Human_Resources"
                {...nextactive}
                onClick={() => {
                  nextactive(null, "Human_Resources");
                }}
              >
                Human Resources
              </div>
              <div
                className="tagbutton Marketing"
                {...nextactive}
                onClick={() => {
                  nextactive(null, "Marketing");
                }}
              >
                Marketing
              </div>
              <div
                className="tagbutton Product"
                {...nextactive}
                onClick={() => {
                  nextactive(null, "Product");
                }}
              >
                Product
              </div>
              <div
                className="tagbutton Technology"
                {...nextactive}
                onClick={() => {
                  nextactive(null, "Technology");
                }}
              >
                Technology
              </div>
              <div
                className="tagbutton UI_UX_Design"
                {...nextactive}
                onClick={() => {
                  nextactive(null, "UI_UX_Design");
                }}
              >
                UI/UX Design
              </div>
            </div>
            <OpenPositions postsToRender={dataopening} />
          </div>
        </div>

        <div id="culture" className=" culturesec">
          <OurCulture />
        </div>
        <div className=" joinus">
          <JoinUs btnName={"Join Us"} href="https://forms.gle/9VrJV3juJAxPHgUB6" target="_blank">
            <h4 className="title">You have something more to offer?</h4>
            <p className="description">
              Make us an offer we can’t refuse. If you know how to push for
              win-win situations, you will certainly find an environment that
              enables you to do so at AppyHigh. <br />
              Please surprise us.
            </p>
          </JoinUs>
        </div>
        <section>
          <Footer pagename={"Careers"} />
        </section>
      </div>
    </>
  );
};

export default Careers;
