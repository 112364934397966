import React from "react";

// sections
import HeroSec from "../../components/jobdetails/hero";
import Footer from "../../components/footer";
import AboutSec from '../../components/jobdetails/aboutsec';
import PerksSec from '../../components/jobdetails/perks';

// Styles
import "./styles.scss";
import HelmetCustom from "../../components/helmetCustom";

const JobDetail = (props) => {
  // debugger;
  if (props.location.careerprops) {
    // debugger;
    window.localStorage.setItem('content', JSON.stringify(props.location.careerprops.name));
  }
  let content = JSON.parse(window.localStorage.getItem('content'));
  return (
    <>
      <HelmetCustom title="Job Details" metaDescription="Imagine a world where living is simple, decluttered and enriching. We are creating that reality, one app at a time!" />
      <div className="career-page-detail">
        <div>
          <HeroSec data={content} />
        </div>

        <div>
          <AboutSec data={content} />
        </div>

        <div className="perkssec">
          <PerksSec data={content} />
        </div>

        <section>
          <Footer />
        </section>
      </div>
    </>
  );
};

export default JobDetail;