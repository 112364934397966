import { Link } from "react-router-dom";
import React from "react";

// Styles
import "./styles.scss";

const NotFound = () => {

  return (
    <div className="not-found">
      <div className="text-section">
        <div className="text-404">
          404
        </div>
        <div className="text-description">
          Ooops! Page not found
        </div>
        <a className="back-btn-style" href="/">
          Back to Home
        </a>
      </div>
    </div>
  );
};

export default NotFound;
