// Images
import img1 from "../../../assets/images/home/fuelling-imagination/a3.svg"
import img2 from "../../../assets/images/home/fuelling-imagination/a1.svg"
import img3 from "../../../assets/images/home/fuelling-imagination/a2.svg"
import img4 from "../../../assets/images/home/fuelling-imagination/a4.svg"

import "./styles.scss";

const ratingCards = [
  {
    image: img1,
    title: "Startups supported",
    number: "50+",
  },
  {
    image: img2,
    title: "Average Deals/ Month",
    number: "2.5",
  },
  {
    image: img3,
    title: "Up-Rounds",
    number: "25+",
  },
];

const RatingCounter = () => {
  return (
    <div className="rating-counter-sec">
      <div className="container">
        <div className="row">
          {ratingCards.map((data, index) => {
            return (
              <div className="col-sm-4 col-4 padding-sm">
                <div className="achievement-box">
                  <h3 className="title-lg">{data.number}</h3>
                  <div className="info-text">{data.title}</div>
                  <div className="img-sec">
                    <img src={data.image} alt="icon image" />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RatingCounter;
