import React, { useState } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.scss";

// Images
import masterfeedlogo from "../../../assets/images/product/hero/MasterFeed.png";
import masterfeedscreen1 from "../../../assets/images/product/superapps/MasterFeed/WEB_ASSETS_MASTERFEED_01-100.png";
import masterfeedscreen2 from "../../../assets/images/product/superapps/MasterFeed/WEB_ASSETS_MASTERFEED_02-100.png";
import masterfeedscreen3 from "../../../assets/images/product/superapps/MasterFeed/WEB_ASSETS_MASTERFEED_03-100.png";
import masterfeedscreen4 from "../../../assets/images/product/superapps/MasterFeed/WEB_ASSETS_MASTERFEED_04-100.png";
import masterfeedscreen5 from "../../../assets/images/product/superapps/MasterFeed/WEB_ASSETS_MASTERFEED_05-100.png";
import masterfeedscreen6 from "../../../assets/images/product/superapps/MasterFeed/MasterFeed_dark_6.png";
import appstore from "../../../assets/images/product/appstore.png";
import comingsoonappstore from "../../../assets/images/product/comingsoonappstore.png";
import playstore from "../../../assets/images/product/playstore.png";
import sharekaro from "../../../assets/images/product/sharekaro.png";
import sharekaro1 from "../../../assets/images/product/sharekaro1.png";
import sharekaro2 from "../../../assets/images/product/sharekaro2.png";
import sharekaro3 from "../../../assets/images/product/sharekaro3.png";
import sharekaro4 from "../../../assets/images/product/sharekaro4.png";
import sharekaro5 from "../../../assets/images/product/sharekaro5.png";
import sharekaro6 from "../../../assets/images/product/sharekaro6.png";
import MessengerGologo from "../../../assets/images/product/hero/MessengerGo.png";
import MessengerGoscreen1 from "../../../assets/images/product/superapps/MeesengerGo/MaskGroup1.png";
import MessengerGoscreen2 from "../../../assets/images/product/superapps/MeesengerGo/MaskGroup2.png";
import MessengerGoscreen3 from "../../../assets/images/product/superapps/MeesengerGo/MaskGroup3.png";
import MessengerGoscreen4 from "../../../assets/images/product/superapps/MeesengerGo/MaskGroup4.png";
import MessengerGoscreen5 from "../../../assets/images/product/superapps/MeesengerGo/MaskGroup5.png";
import MessengerGoscreen6 from "../../../assets/images/product/superapps/MeesengerGo/MaskGroup6.png";
import MessengerGoscreen7 from "../../../assets/images/product/superapps/MeesengerGo/MaskGroup7.png";
import MessengerGoscreen8 from "../../../assets/images/product/superapps/MeesengerGo/MaskGroup8.png";
import browsergologo from "../../../assets/images/product/hero/BrowserGO.png";
import browsergoscreen1 from "../../../assets/images/product/superapps/BrowserGo/BrowserGo1.png";
import browsergoscreen2 from "../../../assets/images/product/superapps/BrowserGo/BrowserGo2.png";
import browsergoscreen3 from "../../../assets/images/product/superapps/BrowserGo/BrowserGo3.png";
import browsergoscreen4 from "../../../assets/images/product/superapps/BrowserGo/BrowserGo4.png";
import browsergoscreen5 from "../../../assets/images/product/superapps/BrowserGo/BrowserGo5.png";
import browsergoscreen6 from "../../../assets/images/product/superapps/BrowserGo/BrowserGo6.png";
import smartshopperlogo from "../../../assets/images/product/hero/SmartShopper.png";
import smartshopperscreen1 from "../../../assets/images/product/superapps/SmartShopper/SmartShopper1.png";
import smartshopperscreen2 from "../../../assets/images/product/superapps/SmartShopper/SmartShopper2.png";
import smartshopperscreen3 from "../../../assets/images/product/superapps/SmartShopper/SmartShopper3.png";
import smartshopperscreen4 from "../../../assets/images/product/superapps/SmartShopper/SmartShopper4.png";
import smartshopperscreen5 from "../../../assets/images/product/superapps/SmartShopper/SmartShopper5.png";
import smartshopperscreen6 from "../../../assets/images/product/superapps/SmartShopper/SmartShopper6.png";
import smartshopperscreen7 from "../../../assets/images/product/superapps/SmartShopper/SmartShopper7.png";
import smartshopperscreen8 from "../../../assets/images/product/superapps/SmartShopper/SmartShopper8.png";
import Applockgo from "../../../assets/images/product/hero/Applockgo.png";
import Applockgoscreen1 from "../../../assets/images/product/superapps/Applock/Applock1.png";
import Applockgoscreen2 from "../../../assets/images/product/superapps/Applock/Applock2.png";
import Applockgoscreen3 from "../../../assets/images/product/superapps/Applock/Applock3.png";
import Applockgoscreen4 from "../../../assets/images/product/superapps/Applock/Applock4.png";
import Applockgoscreen5 from "../../../assets/images/product/superapps/Applock/Applock5.png";
import Applockgoscreen6 from "../../../assets/images/product/superapps/Applock/Applock6.png";
import ScannerGO from "../../../assets/images/product/hero/ScannerGO.png";
import ScannerGOscreen1 from "../../../assets/images/product/superapps/ScannerGo/ScannerGO1.png";
import ScannerGOscreen2 from "../../../assets/images/product/superapps/ScannerGo/ScannerGO2.png";
import ScannerGOscreen3 from "../../../assets/images/product/superapps/ScannerGo/ScannerGO3.png";
import ScannerGOscreen4 from "../../../assets/images/product/superapps/ScannerGo/ScannerGO4.png";
import ScannerGOscreen5 from "../../../assets/images/product/superapps/ScannerGo/ScannerGO5.png";
import ScannerGOscreen6 from "../../../assets/images/product/superapps/ScannerGo/ScannerGO6.png";
import ScannerGOscreen7 from "../../../assets/images/product/superapps/ScannerGo/ScannerGO7.png";
import fontslogo from "../../../assets/images/product/hero/Fontapp.png";
import fontsscreen1 from "../../../assets/images/product/utility/Fonts/fontsscreen1.png";
import fontsscreen2 from "../../../assets/images/product/utility/Fonts/fontsscreen2.png";
import fontsscreen3 from "../../../assets/images/product/utility/Fonts/fontsscreen3.png";
import fontsscreen4 from "../../../assets/images/product/utility/Fonts/fontsscreen4.png";
import fontsscreen5 from "../../../assets/images/product/utility/Fonts/fontsscreen5.png";
import QRCodeReaderlogo from "../../../assets/images/product/hero/QRCodeReader.png";
import QRCodeReaderscreen1 from "../../../assets/images/product/utility/QrCode/QRCodeReaderscreen1.png";
import QRCodeReaderscreen2 from "../../../assets/images/product/utility/QrCode/QRCodeReaderscreen2.png";
import QRCodeReaderscreen3 from "../../../assets/images/product/utility/QrCode/QRCodeReaderscreen3.png";
import QRCodeReaderscreen4 from "../../../assets/images/product/utility/QrCode/QRCodeReaderscreen4.png";
import QRCodeReaderscreen5 from "../../../assets/images/product/utility/QrCode/QRCodeReaderscreen5.png";
import QRCodeReaderscreen6 from "../../../assets/images/product/utility/QrCode/QRCodeReaderscreen6.png";
import QRCodeReaderscreen7 from "../../../assets/images/product/utility/QrCode/QRCodeReaderscreen7.png";
import InStory from "../../../assets/images/product/Instory.png";
import InStoryscreen1 from "../../../assets/images/product/utility/StoryMaker/StoryMaker1.png";
import InStoryscreen2 from "../../../assets/images/product/utility/StoryMaker/StoryMaker2.png";
import InStoryscreen3 from "../../../assets/images/product/utility/StoryMaker/StoryMaker3.png";
import InStoryscreen4 from "../../../assets/images/product/utility/StoryMaker/StoryMaker4.png";
import InStoryscreen5 from "../../../assets/images/product/utility/StoryMaker/StoryMaker5.png";
import TVLens from "../../../assets/images/product/hero/TVLens.png";
import TVLensscreen1 from "../../../assets/images/product/content/LiveTv/TVLensscreen1.png";
import TVLensscreen2 from "../../../assets/images/product/content/LiveTv/TVLensscreen2.png";
import TVLensscreen3 from "../../../assets/images/product/content/LiveTv/TVLensscreen3.png";
import TVLensscreen4 from "../../../assets/images/product/content/LiveTv/TVLensscreen4.png";
import TVLensscreen5 from "../../../assets/images/product/content/LiveTv/TVLensscreen5.png";
import TVLensscreen6 from "../../../assets/images/product/content/LiveTv/TVLensscreen6.png";
import SocialGo from "../../../assets/images/product/hero/SocialGo.png";
import SocialGoscreen1 from "../../../assets/images/product/content/SocialGo/SocialGoscreen1.png";
import SocialGoscreen2 from "../../../assets/images/product/content/SocialGo/SocialGoscreen2.png";
import SocialGoscreen3 from "../../../assets/images/product/content/SocialGo/SocialGoscreen3.png";
import SocialGoscreen4 from "../../../assets/images/product/content/SocialGo/SocialGoscreen4.png";
import SocialGoscreen5 from "../../../assets/images/product/content/SocialGo/SocialGoscreen5.png";
import SocialGoscreen6 from "../../../assets/images/product/content/SocialGo/SocialGoscreen6.png";
import SocialGoscreen7 from "../../../assets/images/product/content/SocialGo/SocialGoscreen7.png";
import SocialGoscreen9 from "../../../assets/images/product/content/SocialGo/SocialGoscreen8.png";
import Pally from "../../../assets/images/product/hero/Pally.png";
import PallyScreen1 from "../../../assets/images/product/livestreaming/PallyLive/PallyScreen1.png";
import PallyScreen2 from "../../../assets/images/product/livestreaming/PallyLive/PallyScreen2.png";
import PallyScreen3 from "../../../assets/images/product/livestreaming/PallyLive/PallyScreen3.png";
import PallyScreen4 from "../../../assets/images/product/livestreaming/PallyLive/PallyScreen4.png";
import PallyScreen5 from "../../../assets/images/product/livestreaming/PallyLive/PallyScreen5.png";
import PallyScreen6 from "../../../assets/images/product/livestreaming/PallyLive/PallyScreen6.png";
import PallyScreen7 from "../../../assets/images/product/livestreaming/PallyLive/PallyScreen7.png";
import PallyScreen8 from "../../../assets/images/product/livestreaming/PallyLive/PallyScreen8.png";
import InStore from "../../../assets/images/product/Instore.png";
import InStoreScreen1 from "../../../assets/images/product/utility/Instore/InStoreScreen1.png";
import InStoreScreen2 from "../../../assets/images/product/utility/Instore/InStoreScreen2.png";
import InStoreScreen3 from "../../../assets/images/product/utility/Instore/InStoreScreen3.png";
import InStoreScreen4 from "../../../assets/images/product/utility/Instore/InStoreScreen4.png";
import InStoreScreen5 from "../../../assets/images/product/utility/Instore/InStoreScreen5.png";
import InStoreScreen6 from "../../../assets/images/product/utility/Instore/InStoreScreen6.png";
import InStoreScreen7 from "../../../assets/images/product/utility/Instore/InStoreScreen7.png";
import InStoreScreen8 from "../../../assets/images/product/utility/Instore/InStoreScreen8.png";
import logocleaner from "../../../assets/images/product/utility/cleanergo/logocleaner.png";
import cleanergo1 from "../../../assets/images/product/utility/cleanergo/cleanergo1.png";
import cleanergo2 from "../../../assets/images/product/utility/cleanergo/cleanergo2.png";
import cleanergo3 from "../../../assets/images/product/utility/cleanergo/cleanergo3.png";
import cleanergo4 from "../../../assets/images/product/utility/cleanergo/cleanergo4.png";
import cleanergo5 from "../../../assets/images/product/utility/cleanergo/cleanergo5.png";
import cleanergo6 from "../../../assets/images/product/utility/cleanergo/cleanergo6.png";
import screenrecoredr1 from "../../../assets/images/product/utility/screenrecorder/Screen recorder 1.png";
import screenrecoredr2 from "../../../assets/images/product/utility/screenrecorder/Screen recorder 2.png";
import screenrecoredr3 from "../../../assets/images/product/utility/screenrecorder/Screen recorder 3.png";
import screenrecoredr4 from "../../../assets/images/product/utility/screenrecorder/Screen recorder 4.png";
import screenrecoredr5 from "../../../assets/images/product/utility/screenrecorder/Screen recorder 5.png";
import Instats from "../../../assets/images/product/hero/Instats.png";
import Instats1 from "../../../assets/images/product/livestreaming/Instats/Instats1.png";
import Instats2 from "../../../assets/images/product/livestreaming/Instats/Instats2.png";
import Instats3 from "../../../assets/images/product/livestreaming/Instats/Instats3.png";
import Instats4 from "../../../assets/images/product/livestreaming/Instats/Instats4.png";
import Instats5 from "../../../assets/images/product/livestreaming/Instats/Instats5.png";
import Instats6 from "../../../assets/images/product/livestreaming/Instats/Instats6.png";
import Kyral from "../../../assets/images/product/hero/Kyral.png";
import Kyralscreen1 from "../../../assets/images/product/CreatorEcosystem/Kyral/Kyralscreen1.png";
import Kyralscreen2 from "../../../assets/images/product/CreatorEcosystem/Kyral/Kyralscreen2.png";
import Kyralscreen3 from "../../../assets/images/product/CreatorEcosystem/Kyral/Kyralscreen3.png";
import Kyralscreen4 from "../../../assets/images/product/CreatorEcosystem/Kyral/Kyralscreen4.png";
import Kyralscreen5 from "../../../assets/images/product/CreatorEcosystem/Kyral/Kyralscreen5.png";
import Kyralscreen6 from "../../../assets/images/product/CreatorEcosystem/Kyral/Kyralscreen6.png";
import Kyralscreen7 from "../../../assets/images/product/CreatorEcosystem/Kyral/Kyralscreen7.png";
import Kyralscreen8 from "../../../assets/images/product/CreatorEcosystem/Kyral/Kyralscreen8.png";
import LikeKaro from "../../../assets/images/product/LikeKaro.png";
import LikeKaroscreen1 from "../../../assets/images/product/CreatorEcosystem/LikeKaro/LikeKaroscreen1.png";
import LikeKaroscreen2 from "../../../assets/images/product/CreatorEcosystem/LikeKaro/LikeKaroscreen2.png";
import LikeKaroscreen3 from "../../../assets/images/product/CreatorEcosystem/LikeKaro/LikeKaroscreen3.png";
import LikeKaroscreen4 from "../../../assets/images/product/CreatorEcosystem/LikeKaro/LikeKaroscreen4.png";
import MVEditor from "../../../assets/images/product/hero/MVEditor.png";
import MVEditorscreen1 from "../../../assets/images/product/CreatorEcosystem/MVVideoMaker/MVEditorscreen1.png";
import MVEditorscreen2 from "../../../assets/images/product/CreatorEcosystem/MVVideoMaker/MVEditorscreen2.png";
import MVEditorscreen3 from "../../../assets/images/product/CreatorEcosystem/MVVideoMaker/MVEditorscreen3.png";
import MVEditorscreen4 from "../../../assets/images/product/CreatorEcosystem/MVVideoMaker/MVEditorscreen4.png";
import MVEditorscreen5 from "../../../assets/images/product/CreatorEcosystem/MVVideoMaker/MVEditorscreen5.png";
import MVEditorscreen6 from "../../../assets/images/product/CreatorEcosystem/MVVideoMaker/MVEditorscreen6.png";
import screenrecorderlogo from "../../../assets/images/product/ScreenLogoRecorder.png";

// styles
import "slick-carousel/slick/slick.css";
import "./portfolio.scss";

const Portfolio = (props) => {
  const [tabIndex, setTabIndex] = useState(0);
  const settings = {
    dots: false,
    speed: 500,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    variableWidth: true,
  };
  if (window.location.hash == "#Creators") {
    setTimeout(() => {
      if (
        !document
          .getElementsByClassName("react-tabs__tab-list")[0]
          .children[4].classList.contains("react-tabs__tab--selected")
      ) {
        document
          .getElementsByClassName("react-tabs__tab-list")[0]
          .children[4].click();
      }
    }, 300);
  }
  if (window.location.hash == "#SuperApps") {
    setTimeout(() => {
      if (
        !document
          .getElementsByClassName("react-tabs__tab-list")[0]
          .children[0].classList.contains("react-tabs__tab--selected")
      ) {
        document
          .getElementsByClassName("react-tabs__tab-list")[0]
          .children[0].click();
      }
    }, 300);
  }
  if (window.location.hash == "#Utility") {
    setTimeout(() => {
      if (
        !document
          .getElementsByClassName("react-tabs__tab-list")[0]
          .children[1].classList.contains("react-tabs__tab--selected")
      ) {
        document
          .getElementsByClassName("react-tabs__tab-list")[0]
          .children[1].click();
      }
    }, 300);
  }
  if (window.location.hash == "#Content") {
    setTimeout(() => {
      if (
        !document
          .getElementsByClassName("react-tabs__tab-list")[0]
          .children[2].classList.contains("react-tabs__tab--selected")
      ) {
        document
          .getElementsByClassName("react-tabs__tab-list")[0]
          .children[2].click();
      }
    }, 300);
  }

  return (
    <div className="container-fluid portfolio">
      <div className="introbox">
        <div className="title">OUR PORTFOLIO</div>
        <div className="content">
          Crafted with passion, <br /> built with precision
        </div>
      </div>
      <div className="portfolioBox">
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList>
            <Tab>
              <span id="SuperApps">SuperApps</span>
            </Tab>
            <Tab>
              <span id="Utility">Utility</span>
            </Tab>
            <Tab>
              <span id="Content">Content</span>
            </Tab>
            <Tab>Live Streaming</Tab>
            <Tab>
              <span id="Creators">Creator Ecosystem</span>
            </Tab>
          </TabList>
          <TabPanel>
            <div className="card">
              <div className="contentArea">
                <div className="detailsArea">
                  <div className="logobox">
                    <img src={masterfeedlogo} alt="SUPERAPPS"  />
                  </div>
                  <div className="category">SUPERAPPS</div>
                  <div className="name">MasterFeed</div>
                  <div className="description">
                    India's first AI-powered personalized feed for news, social, videos, podcasts and all things content! The feed is integrated in multiple apps.
                  </div>
                  <div className="nosDownloads">
                    <div>
                      <div className="title">Downloads</div>
                      <div className="numbers">30M+</div>
                    </div>
                  </div>
                  <div className="availabletext">Available on</div>
                  <div className="available_box">
                    <div>
                      <a href="">
                        <img src={comingsoonappstore} alt="Available"  />
                      </a>
                    </div>
                    <div>
                      <a target="_blank" href="https://play.google.com/store/apps/details?id=master.feed.news.video">
                        <img src={playstore} alt="playstore"  />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider">
                <a target="_blank" href="https://play.google.com/store/apps/details?id=master.feed.news.video">
                  <Slider {...settings}>
                    <div>
                      <img src={masterfeedscreen1} alt="master feed"  />
                    </div>
                    <div>
                      <img src={masterfeedscreen2} alt="master feed"  />
                    </div>
                    <div>
                      <img src={masterfeedscreen3} alt="master feed"  />
                    </div>
                    <div>
                      <img src={masterfeedscreen6} alt="master feed"  />
                    </div>
                    <div>
                      <img src={masterfeedscreen4} alt="master feed"  />
                    </div>
                    <div>
                      <img src={masterfeedscreen5} alt="master feed"  />
                    </div>
                  </Slider>
                </a>
              </div>
            </div>
            <div className="card">
              <div className="contentArea">
                <div className="detailsArea">
                  <div className="logobox">
                    <img src={sharekaro} alt="sharekaro" />
                  </div>
                  <div className="category">SUPERAPPS</div>
                  <div className="name">Share Karo India</div>
                  <div className="description">
                    The fastest, social sharing App for images, videos,
                    documents and other files. One-stop destination for curated
                    content discovery via an intelligent AI/ML-based
                    recommendation engine and sharing.
                  </div>
                  <div className="nosDownloads">
                    <div>
                      <div className="title">Downloads</div>
                      <div className="numbers">65M+</div>
                    </div>
                  </div>
                  <div className="availabletext">Available on</div>
                  <div className="available_box">
                    <div>
                      <a href="">
                        <img src={comingsoonappstore} alt="coming soon" />
                      </a>
                    </div>
                    <div>
                      <a target="_blank" href="https://play.google.com/store/apps/details?id=com.sharekaro.shareit">
                        <img src={playstore} alt="playstore" />
                      </a>
                    </div>
                  </div>
                </div>

              </div>
              <div className="slider">
                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.sharekaro.shareit">
                  <Slider {...settings}>
                    <div>
                      <img src={sharekaro1}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={sharekaro2}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={sharekaro3}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={sharekaro4}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={sharekaro5}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={sharekaro6}  alt="appyhigh"/>
                    </div>
                  </Slider>
                </a>
              </div>
            </div>

            <div className="card">
              <div className="contentArea">
                <div className="detailsArea">
                  <div className="logobox">
                    <img src={MessengerGologo}  alt="appyhigh"/>
                  </div>
                  <div className="category">SUPERAPPS</div>
                  <div className="name">Messenger Go</div>
                  <div className="description">
                    MessengerGo app is a comprehensive platform that gives users
                    access to all messengers, social media applications, browser
                    and a personalised content feed.
                  </div>
                  <div className="nosDownloads">
                    <div>
                      <div className="title">Downloads</div>
                      <div className="numbers">130Mn+</div>
                    </div>
                  </div>
                  <div className="availabletext">Available on</div>
                  <div className="available_box">
                    <div>
                      <a href="">
                        <img src={comingsoonappstore}  alt="appyhigh"/>
                      </a>
                    </div>
                    <div>
                      <a target="_blank" href="https://play.google.com/store/apps/details?id=messenger.chat.social.messenger">
                        <img src={playstore}  alt="appyhigh"/>
                      </a>
                    </div>
                  </div>
                </div>

              </div>
              <div className="slider">
                <a target="_blank" href="https://play.google.com/store/apps/details?id=messenger.chat.social.messenger">
                  <Slider {...settings}>
                    <div>
                      <img src={MessengerGoscreen1}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={MessengerGoscreen2}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={MessengerGoscreen3}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={MessengerGoscreen4}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={MessengerGoscreen5}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={MessengerGoscreen6}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={MessengerGoscreen7}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={MessengerGoscreen8}  alt="appyhigh"/>
                    </div>
                  </Slider>
                </a>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="card">
              <div className="contentArea">
                <div className="detailsArea">
                  <div className="logobox">
                    <img src={browsergologo}  alt="appyhigh"/>
                  </div>
                  <div className="category">Utility</div>
                  <div className="name">Browser Go</div>
                  <div className="description">
                    Browser Go is the new-age web browser that offers speed and
                    stability. It has a multitude of features like night mode,
                    multi-touch, smart suggestions, volume buttons, RSS reader
                    and many more.
                  </div>
                  <div className="nosDownloads">
                    <div>
                      <div className="title">Downloads</div>
                      <div className="numbers">35M+</div>
                    </div>
                  </div>
                  <div className="availabletext">Available on</div>
                  <div className="available_box">
                    <div>
                      <a href="">
                        <img src={comingsoonappstore}  alt="appyhigh"/>
                      </a>
                    </div>
                    <div>
                      <a target="_blank" href="https://play.google.com/store/apps/details?id=u.see.browser.for.uc.browser">
                        <img src={playstore}  alt="appyhigh"/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider">
                <a target="_blank" href="https://play.google.com/store/apps/details?id=u.see.browser.for.uc.browser">
                  <Slider {...settings}>
                    <div>
                      <img src={browsergoscreen1}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={browsergoscreen2}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={browsergoscreen3}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={browsergoscreen4}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={browsergoscreen5}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={browsergoscreen6}  alt="appyhigh"/>
                    </div>
                  </Slider>
                </a>
              </div>
            </div>

            <div className="card">
              <div className="contentArea">
                <div className="detailsArea">
                  <div className="logobox">
                    <img src={Applockgo}  alt="appyhigh"/>
                  </div>
                  <div className="category">Utility</div>
                  <div className="name">AppLock Go</div>
                  <div className="description">
                    AppLock Go safeguards users chats, photos, settings, apps
                    and more. Comes with add-on features like app cleaner, ram
                    booster and more.
                  </div>
                  <div className="nosDownloads">
                    <div>
                      <div className="title">Downloads</div>
                      <div className="numbers">25M+</div>
                    </div>
                  </div>
                  <div className="availabletext">Available on</div>
                  <div className="available_box">
                    <div>
                      <a href="">
                        <img src={comingsoonappstore}  alt="appyhigh"/>
                      </a>
                    </div>
                    <div>
                      <a target="_blank" href="https://play.google.com/store/apps/details?id=app.locker.fingerprint.applock.lockapps">
                        <img src={playstore}  alt="appyhigh"/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider">
                <a target="_blank" href="https://play.google.com/store/apps/details?id=app.locker.fingerprint.applock.lockapps">
                  <Slider {...settings}>
                    <div>
                      <img src={Applockgoscreen1}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={Applockgoscreen2}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={Applockgoscreen3}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={Applockgoscreen4}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={Applockgoscreen5}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={Applockgoscreen6}  alt="appyhigh"/>
                    </div>
                  </Slider>
                </a>
              </div>
            </div>

            <div className="card">
              <div className="contentArea">
                <div className="detailsArea">
                  <div className="logobox">
                    <img src={ScannerGO}  alt="appyhigh"/>
                  </div>
                  <div className="category">Utility</div>
                  <div className="name">Scanner Go</div>
                  <div className="description">
                    A light-weight app that turns users phone into a scanner.
                    Supports a bunch of smart features around scanning, editing
                    and sharing.
                  </div>
                  <div className="nosDownloads">
                    <div>
                      <div className="title">Downloads</div>
                      <div className="numbers">20M+</div>
                    </div>
                  </div>
                  <div className="availabletext">Available on</div>
                  <div className="available_box">
                    <div>
                      <a href="">
                        <img src={appstore}  alt="appyhigh"/>
                      </a>
                    </div>
                    <div>
                      <a target="_blank" href="https://play.google.com/store/apps/details?id=com.cam.scanner.camscanner.documentscanner">
                        <img src={playstore}  alt="appyhigh"/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider">
                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.cam.scanner.camscanner.documentscanner">
                  <Slider {...settings}>
                    <div>
                      <img src={ScannerGOscreen1}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={ScannerGOscreen2}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={ScannerGOscreen3}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={ScannerGOscreen4}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={ScannerGOscreen5}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={ScannerGOscreen6}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={ScannerGOscreen7}  alt="appyhigh"/>
                    </div>
                  </Slider>
                </a>
              </div>
            </div>

            <div className="card">
              <div className="contentArea">
                <div className="detailsArea">
                  <div className="logobox">
                    <img src={smartshopperlogo}  alt="appyhigh"/>
                  </div>
                  <div className="category">Utility</div>
                  <div className="name">Smart Shoppr</div>
                  <div className="description">
                    All-in-1 Shopping App to browse latest deals & offers from
                    Top Indian online shopping apps & sites.
                  </div>
                  <div className="nosDownloads">
                    <div>
                      <div className="title">Downloads</div>
                      <div className="numbers">6M+</div>
                    </div>
                  </div>
                  <div className="availabletext">Available on</div>
                  <div className="available_box">
                    <div>
                      <a href="">
                        <img src={comingsoonappstore}  alt="appyhigh"/>
                      </a>
                    </div>
                    <div>
                      <a target="_blank" href="https://play.google.com/store/apps/details?id=shopall.compare.onlineshopping.shopping&hl=en&gl=US">
                        <img src={playstore}  alt="appyhigh"/>
                      </a>
                    </div>
                  </div>
                </div>

              </div>
              <div className="slider">
                <a target="_blank" href="https://play.google.com/store/apps/details?id=shopall.compare.onlineshopping.shopping&hl=en&gl=US">
                  <Slider {...settings}>
                    <div>
                      <img src={smartshopperscreen1}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={smartshopperscreen2}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={smartshopperscreen3}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={smartshopperscreen4}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={smartshopperscreen5}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={smartshopperscreen6}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={smartshopperscreen7}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={smartshopperscreen8}  alt="appyhigh"/>
                    </div>
                  </Slider>
                </a>
              </div>
            </div>

            <div className="card">
              <div className="contentArea">
                <div className="detailsArea">
                  <div className="logobox">
                    <img src={fontslogo}  alt="appyhigh"/>
                  </div>
                  <div className="category">Utility</div>
                  <div className="name">Fonts App</div>
                  <div className="description">
                    A quick tool for turning boring chats into exciting ones
                    with a range of fancy fonts. Supports all social media and
                    text editing applications.
                  </div>
                  <div className="nosDownloads">
                    <div>
                      <div className="title">Downloads</div>
                      <div className="numbers">1.5M+</div>
                    </div>
                  </div>
                  <div className="availabletext">Available on</div>
                  <div className="available_box">
                    <div>
                      <a href="">
                        <img src={comingsoonappstore}  alt="appyhigh"/>
                      </a>
                    </div>
                    <div>
                      <a target="_blank" href="https://play.google.com/store/apps/details?id=free.stylish.text.stylish.fonts.fancytext">
                        <img src={playstore}  alt="appyhigh"/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider">
                <a target="_blank" href="https://play.google.com/store/apps/details?id=free.stylish.text.stylish.fonts.fancytext">
                  <Slider {...settings}>
                    <div>
                      <img src={fontsscreen1}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={fontsscreen2}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={fontsscreen3}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={fontsscreen4}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={fontsscreen5}  alt="appyhigh"/>
                    </div>
                  </Slider>
                </a>
              </div>
            </div>

            <div className="card">
              <div className="contentArea">
                <div className="detailsArea">
                  <div className="logobox">
                    <img src={QRCodeReaderlogo}  alt="appyhigh"/>
                  </div>
                  <div className="category">Utility</div>
                  <div className="name">QR Code Scanner</div>
                  <div className="description">
                    Not just a super-fast QR and Barcode reader, but turns your
                    phone into a QR code generator for everything from URLs to
                    contacts.
                  </div>
                  <div className="nosDownloads">
                    <div>
                      <div className="title">Downloads</div>
                      <div className="numbers">3M+</div>
                    </div>
                  </div>
                  <div className="availabletext">Available on</div>
                  <div className="available_box">
                    <div>
                      <a href="">
                        <img src={appstore}  alt="appyhigh"/>
                      </a>
                    </div>
                    <div>
                      <a target="_blank" href="https://play.google.com/store/apps/details?id=qr.code.reader.barcode.scanner.qrcodeScanner&hl=en_US&gl=US">
                        <img src={playstore}  alt="appyhigh"/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider">
                <a target="_blank" href="https://play.google.com/store/apps/details?id=qr.code.reader.barcode.scanner.qrcodeScanner&hl=en_US&gl=US">
                  <Slider {...settings}>
                    <div>
                      <img src={QRCodeReaderscreen1}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={QRCodeReaderscreen2}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={QRCodeReaderscreen3}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={QRCodeReaderscreen4}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={QRCodeReaderscreen5}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={QRCodeReaderscreen6}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={QRCodeReaderscreen7}  alt="appyhigh"/>
                    </div>
                  </Slider>
                </a>
              </div>
            </div>

            <div className="card">
              <div className="contentArea">
                <div className="detailsArea">
                  <div className="logobox">
                    <img src={logocleaner}  alt="appyhigh"/>
                  </div>
                  <div className="category">Utility</div>
                  <div className="name">Cleaner Go</div>
                  <div className="description">
                    Cleaner Go is an android mobile phone cache cleaner, speed
                    booster and memory booster.
                  </div>
                  <div className="nosDownloads">
                    <div>
                      <div className="title">Downloads</div>
                      <div className="numbers justlaunched">Just Launched</div>
                    </div>
                  </div>
                  <div className="availabletext">Available on</div>
                  <div className="available_box">
                    <div>
                      <a href="">
                        <img src={comingsoonappstore}  alt="appyhigh"/>
                      </a>
                    </div>
                    <div>
                      <a target="_blank" href="https://play.google.com/store/apps/details?id=phone.clean.master.cleaner&hl=en_US&gl=US">
                        <img src={playstore}  alt="appyhigh"/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider">
                <a target="_blank" href="https://play.google.com/store/apps/details?id=phone.clean.master.cleaner&hl=en_US&gl=US">
                  <Slider {...settings}>
                    <div>
                      <img src={cleanergo1}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={cleanergo2}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={cleanergo3}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={cleanergo4}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={cleanergo5}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={cleanergo6}  alt="appyhigh"/>
                    </div>
                  </Slider>
                </a>
              </div>
            </div>

            <div className="card">
              <div className="contentArea">
                <div className="detailsArea">
                  <div className="logobox">
                    <img src={screenrecorderlogo}  alt="appyhigh"/>
                  </div>
                  <div className="category">Utility</div>
                  <div className="name">Screen Recorder</div>
                  <div className="description">
                    Screen Recorder is a stable and powerful video recorder to
                    record mobile screens with audio.
                  </div>
                  <div className="nosDownloads">
                    <div>
                      <div className="title">Downloads</div>
                      <div className="numbers">300K+</div>
                    </div>
                  </div>
                  <div className="availabletext">Available on</div>
                  <div className="available_box">
                    <div>
                      <a href="">
                        <img src={comingsoonappstore}  alt="appyhigh"/>
                      </a>
                    </div>
                    <div>
                      <a target="_blank" href="https://play.google.com/store/apps/details?id=com.recordscreenvideo.screenrecorder&hl=en_US&gl=US">
                        <img src={playstore}  alt="appyhigh"/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider">
                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.recordscreenvideo.screenrecorder&hl=en_US&gl=US">
                  <Slider {...settings}>
                    <div>
                      <img src={screenrecoredr1}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={screenrecoredr2}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={screenrecoredr3}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={screenrecoredr4}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={screenrecoredr5}  alt="appyhigh"/>
                    </div>
                  </Slider>
                </a>
              </div>
            </div>

          </TabPanel>
          <TabPanel>
            <div className="card">
              <div className="contentArea">
                <div className="detailsArea">
                  <div className="logobox">
                    <img src={TVLens}  alt="appyhigh"/>
                  </div>
                  <div className="category">Content</div>
                  <div className="name">TV Lens</div>
                  <div className="description">
                    A Movie & TV Show recommendation platform that lets you
                    discover and watch any movie, TV series available on the
                    internet.
                  </div>
                  <div className="nosDownloads">
                    <div>
                      <div className="title">Downloads</div>
                      <div className="numbers">3M+</div>
                    </div>
                  </div>
                  <div className="availabletext">Available on</div>
                  <div className="available_box">
                    <div>
                      <a href="">
                        <img src={comingsoonappstore}  alt="appyhigh"/>
                      </a>
                    </div>
                    <div>
                      <a target="_blank" href="https://play.google.com/store/apps/details?id=movies.old.watched.free&hl=en_NZ&gl=US">
                        <img src={playstore}  alt="appyhigh"/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider">
                <a target="_blank" href="https://play.google.com/store/apps/details?id=movies.old.watched.free&hl=en_NZ&gl=US">
                  <Slider {...settings}>
                    <div>
                      <img src={TVLensscreen1}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={TVLensscreen2}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={TVLensscreen3}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={TVLensscreen4}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={TVLensscreen5}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={TVLensscreen6}  alt="appyhigh"/>
                    </div>
                  </Slider>
                </a>
              </div>
            </div>

            <div className="card">
              <div className="contentArea">
                <div className="detailsArea">
                  <div className="logobox">
                    <img src={SocialGo}  alt="appyhigh"/>
                  </div>
                  <div className="category">Content</div>
                  <div className="name">Social Go</div>
                  <div className="description">
                    Social Go is a video news platform that delivers fastest
                    updates on all breaking, national and regional news in short
                    and long video formats.
                  </div>
                  <div className="nosDownloads">
                    <div>
                      <div className="title">Downloads</div>
                      <div className="numbers justlaunched">Just Launched</div>
                    </div>
                  </div>
                  <div className="availabletext">Available on</div>
                  <div className="available_box">
                    <div>
                      <a href="">
                        <img src={comingsoonappstore}  alt="appyhigh"/>
                      </a>
                    </div>
                    <div>
                      <a target="_blank" href="https://play.google.com/store/apps/details?id=social.local.news.public.video&hl=en_NZ&gl=US">
                        <img src={playstore}  alt="appyhigh"/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider">
                <a target="_blank" href="https://play.google.com/store/apps/details?id=social.local.news.public.video&hl=en_NZ&gl=US">
                  <Slider {...settings}>
                    <div>
                      <img src={SocialGoscreen1}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={SocialGoscreen2}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={SocialGoscreen3}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={SocialGoscreen5}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={SocialGoscreen6}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={SocialGoscreen7}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={SocialGoscreen9}  alt="appyhigh"/>
                    </div>
                  </Slider>
                </a>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="card">
              <div className="contentArea">
                <div className="detailsArea">
                  <div className="logobox">
                    <img src={Pally}  alt="appyhigh"/>
                  </div>
                  <div className="category">Live Streaming</div>
                  <div className="name">Pally</div>
                  <div className="description">
                    A live video chat platform that enables connections for
                    people across countries with fun and engaging chat features,
                    filters, gifts and games.
                  </div>
                  <div className="nosDownloads">
                    <div>
                      <div className="title">Downloads</div>
                      <div className="numbers">11M+</div>
                    </div>
                  </div>
                  <div className="availabletext">Available on</div>
                  <div className="available_box">
                    <div>
                      <a href="">
                        <img src={appstore}  alt="appyhigh"/>
                      </a>
                    </div>
                    <div>
                      <a target="_blank" href="https://play.google.com/store/apps/details?id=com.Pally.Random.Video.Chat.Livetalk.Messenger">
                        <img src={playstore}  alt="appyhigh"/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider">
                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.Pally.Random.Video.Chat.Livetalk.Messenger">
                  <Slider {...settings}>
                    <div>
                      <img src={PallyScreen1}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={PallyScreen2}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={PallyScreen3}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={PallyScreen4}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={PallyScreen5}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={PallyScreen6}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={PallyScreen7}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={PallyScreen8}  alt="appyhigh"/>
                    </div>
                  </Slider>
                </a>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="card">
              <div className="contentArea">
                <div className="detailsArea">
                  <div className="logobox">
                    <img src={InStore}  alt="appyhigh"/>
                  </div>
                  <div className="category">Creator Ecosystem</div>
                  <div className="name">InStore</div>
                  <div className="description">
                    An ultimate tool box for IGers to quickly download posts,
                    videos & stories, get hashtag & caption suggestions, make
                    collages & grids, view stories anonymously and more.
                  </div>
                  <div className="nosDownloads">
                    <div>
                      <div className="title">Downloads</div>
                      <div className="numbers">35M+</div>
                    </div>
                  </div>
                  <div className="availabletext">Available on</div>
                  <div className="available_box">
                    <div>
                      <a href="">
                        <img src={appstore}  alt="appyhigh"/>
                      </a>
                    </div>
                    <div>
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=instagram.photo.video.downloader.repost.insta">
                        <img src={playstore}  alt="appyhigh"/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider">
              <a target="_blank" href="https://play.google.com/store/apps/details?id=instagram.photo.video.downloader.repost.insta">
                <Slider {...settings}>
                  <div>
                    <img src={InStoreScreen1}  alt="appyhigh"/>
                  </div>
                  <div>
                    <img src={InStoreScreen2}  alt="appyhigh"/>
                  </div>
                  <div>
                    <img src={InStoreScreen3}  alt="appyhigh"/>
                  </div>
                  <div>
                    <img src={InStoreScreen4}  alt="appyhigh"/>
                  </div>
                  <div>
                    <img src={InStoreScreen5}  alt="appyhigh"/>
                  </div>
                  <div>
                    <img src={InStoreScreen6}  alt="appyhigh"/>
                  </div>
                  <div>
                    <img src={InStoreScreen7}  alt="appyhigh"/>
                  </div>
                  <div>
                    <img src={InStoreScreen8}  alt="appyhigh"/>
                  </div>
                </Slider>
                </a>
              </div>
            </div>

            <div className="card">
              <div className="contentArea">
                <div className="detailsArea">
                  <div className="logobox">
                    <img src={LikeKaro}  alt="appyhigh"/>
                  </div>
                  <div className="category">Creator Ecosystem</div>
                  <div className="name">Like Karo</div>
                  <div className="description">
                    A short-form video creation & sharing app. Comes with
                    trending video effects, filters and a feed that let's users
                    discover exciting videos.
                  </div>
                  <div className="nosDownloads">
                    <div>
                      <div className="title">Downloads</div>
                      <div className="numbers">7M+</div>
                    </div>
                  </div>
                  <div className="availabletext">Available on</div>
                  <div className="available_box">
                    <div>
                      <a href="">
                        <img src={appstore}  alt="appyhigh"/>
                      </a>
                    </div>
                    <div>
                      <a target="_blank" href="https://play.google.com/store/apps/details?id=playit.videoplayer.musicplayer">
                        <img src={playstore}  alt="appyhigh"/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider">
                <a target="_blank" href="https://play.google.com/store/apps/details?id=playit.videoplayer.musicplayer">
                  <Slider {...settings}>
                    <div>
                      <img src={LikeKaroscreen1}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={LikeKaroscreen2}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={LikeKaroscreen3}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={LikeKaroscreen4}  alt="appyhigh"/>
                    </div>
                  </Slider>
                </a>
              </div>
            </div>

            <div className="card">
              <div className="contentArea">
                <div className="detailsArea">
                  <div className="logobox">
                    <img src={MVEditor}  alt="appyhigh"/>
                  </div>
                  <div className="category">Creator Ecosystem</div>
                  <div className="name">M Video Maker</div>
                  <div className="description">
                    Tool to create videos with photos, music, background
                    effects, filters, transitions and easy share on all social
                    media and status saver apps.
                  </div>
                  <div className="nosDownloads">
                    <div>
                      <div className="title">Downloads</div>
                      <div className="numbers">6M+</div>
                    </div>
                  </div>
                  <div className="availabletext">Available on</div>
                  <div className="available_box">
                    <div>
                      <a href="">
                        <img src={comingsoonappstore}  alt="appyhigh"/>
                      </a>
                    </div>
                    <div>
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=photo.video.editor.slideshow.videomaker">
                        <img src={playstore}  alt="appyhigh"/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider">
              <a target="_blank" href="https://play.google.com/store/apps/details?id=photo.video.editor.slideshow.videomaker">
                <Slider {...settings}>
                  <div>
                    <img src={MVEditorscreen1}  alt="appyhigh"/>
                  </div>
                  <div>
                    <img src={MVEditorscreen2}  alt="appyhigh"/>
                  </div>
                  <div>
                    <img src={MVEditorscreen3}  alt="appyhigh"/>
                  </div>
                  <div>
                    <img src={MVEditorscreen4}  alt="appyhigh"/>
                  </div>
                  <div>
                    <img src={MVEditorscreen5}  alt="appyhigh"/>
                  </div>
                  <div>
                    <img src={MVEditorscreen6}  alt="appyhigh"/>
                  </div>
                </Slider>
                </a>
              </div>
            </div>

            <div className="card">
              <div className="contentArea">
                <div className="detailsArea">
                  <div className="logobox">
                    <img src={Kyral}  alt="appyhigh"/>
                  </div>
                  <div className="category">Creator Ecosystem</div>
                  <div className="name">Kyral</div>
                  <div className="description">
                    A social platform for influencers, creators & artists to
                    connect, collaborate & grow together.
                  </div>
                  <div className="nosDownloads">
                    <div>
                      <div className="title">Downloads</div>
                      <div className="numbers justlaunched">Just Launched</div>
                    </div>
                  </div>
                  <div className="availabletext">Available on</div>
                  <div className="available_box">
                    <div>
                      <a href="">
                        <img src={comingsoonappstore}  alt="appyhigh"/>
                      </a>
                    </div>
                    <div>
                      <a target="_blank" href="https://play.google.com/store/apps/details?id=kyral.influencer.followers.collab">
                        <img src={playstore}  alt="appyhigh"/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider">
                <a target="_blank" href="https://play.google.com/store/apps/details?id=kyral.influencer.followers.collab">
                  <Slider {...settings}>
                    <div>
                      <img src={Kyralscreen1}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={Kyralscreen2}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={Kyralscreen3}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={Kyralscreen4}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={Kyralscreen5}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={Kyralscreen6}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={Kyralscreen7}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={Kyralscreen8}  alt="appyhigh"/>
                    </div>
                  </Slider>
                </a>
              </div>
            </div>

            <div className="card">
              <div className="contentArea">
                <div className="detailsArea">
                  <div className="logobox">
                    <img src={InStory}  alt="appyhigh"/>
                  </div>
                  <div className="category">Creator Ecosystem</div>
                  <div className="name">InStory</div>
                  <div className="description">
                    An app to quickly create easy, beautiful stories for
                    Instagram and other social networks with latest designs,
                    stickers and templates.
                  </div>
                  <div className="nosDownloads">
                    <div>
                      <div className="title">Downloads</div>
                      <div className="numbers">2.5M+</div>
                    </div>
                  </div>
                  <div className="availabletext">Available on</div>
                  <div className="available_box">
                    <div>
                      <a href="">
                        <img src={comingsoonappstore}  alt="appyhigh"/>
                      </a>
                    </div>
                    <div>
                      <a target="_blank" href="https://play.google.com/store/apps/details?id=darkmode.theme.ig.sms.fb.android">
                        <img src={playstore}  alt="appyhigh"/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider">
                <a target="_blank" href="https://play.google.com/store/apps/details?id=darkmode.theme.ig.sms.fb.android">
                  <Slider {...settings}>
                    <div>
                      <img src={InStoryscreen1}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={InStoryscreen2}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={InStoryscreen3}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={InStoryscreen4}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={InStoryscreen5}  alt="appyhigh"/>
                    </div>
                  </Slider>
                </a>
              </div>
            </div>

            <div className="card">
              <div className="contentArea">
                <div className="detailsArea">
                  <div className="logobox">
                    <img src={Instats}  alt="appyhigh"/>
                  </div>
                  <div className="category">Creator Ecosystem</div>
                  <div className="name">InStats</div>
                  <div className="description">
                    An App to keep track of users Instagram profile progress and
                    discover areas of improvement with insights on their IG
                    profile, audience, engagement & competitors.
                  </div>
                  <div className="nosDownloads">
                    <div>
                      <div className="title">Downloads</div>
                      <div className="numbers">3M+</div>
                    </div>
                  </div>
                  <div className="availabletext">Available on</div>
                  <div className="available_box">
                    <div>
                      <a href="">
                        <img src={appstore}  alt="appyhigh"/>
                      </a>
                    </div>
                    <div>
                      <a target="_blank" href="https://play.google.com/store/apps/details?id=instagram.followers.tracker.IG.followeranalyzer">
                        <img src={playstore}  alt="appyhigh"/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider">
                <a target="_blank" href="https://play.google.com/store/apps/details?id=instagram.followers.tracker.IG.followeranalyzer">
                  <Slider {...settings}>
                    <div>
                      <img src={Instats1}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={Instats2}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={Instats3}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={Instats4}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={Instats5}  alt="appyhigh"/>
                    </div>
                    <div>
                      <img src={Instats6}  alt="appyhigh"/>
                    </div>
                  </Slider>
                </a>
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default Portfolio;
