import { Link } from "react-router-dom";
// styles
import "./styles.scss";

import ShareKaro from "../../../assets/images/product/hero/SHAREKaro.png";
import MasterFeed from "../../../assets/images/product/hero/MasterFeed.png";
import MessengerGo from "../../../assets/images/product/hero/MessengerGo.png";

import AppLockgo from "../../../assets/images/product/hero/Applockgo.png";
import ScannerGo from "../../../assets/images/product/hero/ScannerGO.png";
import Cleaner from "../../../assets/images/product/hero/Cleaner.png";
import Fontapp from "../../../assets/images/product/hero/Fontapp.png";
import SmartShopper from "../../../assets/images/product/hero/SmartShopper.png";
import BrowserGO from "../../../assets/images/product/hero/BrowserGO.png";
import QRCodeReader from "../../../assets/images/product/hero/QRCodeReader.png";

import InStore from "../../../assets/images/product/hero/Instore.png";
import MVEditor from "../../../assets/images/product/hero/MVEditor.png";
import Instats from "../../../assets/images/product/hero/Instats.png";
import Kyral from "../../../assets/images/product/hero/Kyral.png";

import Pally from "../../../assets/images/product/hero/Pally.png";
import TVLens from "../../../assets/images/product/hero/TVLens.png";
import LikeKaro from "../../../assets/images/product/hero/LikeKaro.png";
import SocialGo from "../../../assets/images/product/hero/SocialGo.png";

import LogoGrid from "./LogoGrid";

const Combinedsec = (props) => {
  const logos = [
    { url: ShareKaro, name: "Share Karo" },
    { url: MasterFeed, name: "MasterFeed" },
    { url: MessengerGo, name: "Messenger Go" },
  ];

  const utilityAppLogos = [
    { url: AppLockgo, name: "App Lock" },
    { url: ScannerGo, name: "Scanner Go" },
    { url: Cleaner, name: "Cleaner" },
    { url: Fontapp, name: "Font App" },
    { url: SmartShopper, name: "Smart Shopper" },
    { url: BrowserGO, name: "Browser Go" },
    { url: QRCodeReader, name: "QR Code Reader" },
  ];

  const creatorAppLogos = [
    { url: InStore, name: "In Store" },
    { url: Instats, name: "In Stats" },
    { url: Kyral, name: "Kyral" },
    { url: MVEditor, name: "MVEditor" },
  ];

  const contentAppLogos = [
    { url: Pally, name: "Pally" },
    { url: LikeKaro, name: "Like Karo" },
    { url: TVLens, name: "TV Lens" },
    { url: SocialGo, name: "Social Go" },
  ];

  return (
    <div className="combined-section">
      <div className="heading-section">
        <div className="title-lg">The Appyhigh Ecosystem</div>
        <div className="description">
          We are building a diverse, inclusive and sustainable ecosystem
          centered around what we call, the digital Hierarchy of Needs.
        </div>
      </div>
      <div className="mobile-container">
        <div className="enable-container">
          <div className="grid-item-title">We Enable</div>
          <div className="enable-flex">
            <LogoGrid title="Super Apps" logos={logos}></LogoGrid>
            <LogoGrid title="Utility" logos={utilityAppLogos}></LogoGrid>
          </div>
        </div>
        <div className="engage-container">
          <div className="grid-item-title">We Engage</div>
          <LogoGrid
            title="Creator Ecosystem"
            logos={creatorAppLogos}
          ></LogoGrid>
        </div>
        <div className="elevate-container">
          <div className="grid-item-title">We Elevate</div>
          <LogoGrid
            title="Content & Live Streaming"
            logos={contentAppLogos}
          ></LogoGrid>
        </div>
      </div>
    </div>
  );
};

export default Combinedsec;
