// styles
import { useEffect } from "react";
import "./styles.scss";

import introVideo1 from "../../../assets/videos/home-intro1.mp4";

const HeroSec = (props) => {
  useEffect(() => {
    var vid = document.querySelector(".contact-video");
    vid.className = "contact-video loading";
    document
      .getElementById("hero-contact")
      .addEventListener("loadstart", () => {});
    document
      .getElementById("hero-contact")
      .addEventListener("canplaythrough", () => {
        vid.className = "contact-video";
      });
  }, []);
  return (
    <div className="top-banner-contact hero" id="hero">
      <div className="background-container">
        <div className="contact-video">
          <video
            className="introVideo"
            playsInline
            autoPlay
            muted
            loop
            id="hero-contact"
          >
            <source src={introVideo1} type="video/mp4" />
          </video>
        </div>
      </div>

      <div className="content-container" id="container">
        <div className="hero_text">
          <div className="pagetitle">Press & Media</div>

          <h1 className="headtitle">
            One place for all things AppyHigh- <br />
            Logos, assets, information & (h)appy vibes!!
          </h1>
        </div>
      </div>
    </div>
  );
};

export default HeroSec;
