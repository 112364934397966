// Images
import { data } from 'flickity';
import whoweare from '../../../assets/images/aboutus/team/investmentteam/VENUS.png';
import aneesh from '../../../assets/images/aboutus/team/investmentteam/ANEESH.png';
// styles
import "./aboutsec.scss";

const AboutSec = (props) => {
  return (
    <div
      className="container-fluid aboutsec_job">
      <div className="container">
        <div className="aboutparent">
          <div className="title">
            About this position
          </div>
          <div className="boxsec">
            {/* <div className="maincont">
              {props.data ? <>
                {props.data.text}</> : null}
            </div> */}
            <div className="subcontent">
              <div className="title-md">
                {props.data ? <>
                  {props.data.description} </> : null}
              </div>
              <div className="subbox">
                <div>
                  <div className="location">
                    Location
                  </div>
                  <div className="locationname">
                    {props.data ? <>
                      {props.data.location} </> : null}
                  </div>
                </div>
                <div>
                  <div className="location">
                    Employment type
                  </div>
                  <div className="locationname">
                    {props.data ? <>
                      {props.data.jobType} </> : null}
                  </div>
                </div>
                <div>
                  <div className="location">
                    Min. Experience
                  </div>
                  <div className="locationname">
                    {props.data ? <>
                      {props.data.minExperience} </> : null} years
                  </div>
                </div>
                <div>
                  <div className="location">
                    # of positions
                  </div>
                  <div className="locationname">
                    {props.data ? <>
                      {props.data.noOfPositions} </> : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="whowearebox">
          <div className="container">
            <div className="imagebox">
              {props.data.tag == 'Technology' || props.data.tag == 'Data_AI' ? <>
                <img src={aneesh} alt="icon image" /></>
                : <>
                  <img src={whoweare} alt="icon image" />
                </>}
              <div className="name">
                {props.data.tag == 'Technology' || props.data.tag == 'Data_AI' ? <>
                  Aneesh Rayancha</> : <>Venus Dhuria</>}
                <span>
                  Co-Founder
                </span>
              </div>
            </div>
            <div className="contentbox">
              <div className="title">
                Who are we
              </div>
              <div className="text">
                <p>AppyHigh is a mobile internet technology company that builds everyday apps for the masses. We have launched 10+ chartbuster applications that have garnered 400mn+ downloads, and are perpetually hustling to simplify and enrich the digital world.</p>

                <p>We are also a venture capital firm that backs early-stage startups with capital, resources and heart. Founded by third-generation tech entrepreneurs who have made successful exits in the past and backed by an enthusiastic team, Appyhigh is looking to make a mark in the world of internet with finely designed products for the digital masses. If this excites you, then believe us when we say - we have not even scratched the surface yet!</p>
              </div>
            </div>
          </div>
        </div>
        <div className="expectfromyou">
          <div className="title">What we expect from you:</div>
          <div className="lists">
            <ul>
              {props.data ? <>
                {props.data.roles_responsibility.map((data) => {
                  return (
                    <li>{data.text}</li>
                  );
                })} </> : null}
            </ul>
          </div>
        </div>
        <div className="expectfromyou skills_looking">
          <div className="title">Skills that we are looking for: </div>
          <div className="lists">
            <ul>
              {props.data ? <>
                {props.data.skills.map((data) => {
                  return (
                    <li>{data.text}</li>
                  );
                })} </> : null}
            </ul>
          </div>
        </div>

      </div>

    </div>

  );
};

export default AboutSec;
