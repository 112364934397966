import "./styles.scss";
function PhilosophySection(props) {
  return (
    <div className={`philosophy-section-style ${props.classContainer}`}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <div className="info"> The AppyHigh Philosophy</div>
            <h2 className="title">“We pledge more than just funds”</h2>
            <p className="description">
              With our extensive technology and operating backgrounds, we help
              companies anticipate blind spots and get past roadblocks. Think of
              us as both a coach and a teammate. It’s venture capital how it
              should be.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhilosophySection;
