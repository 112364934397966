import Lottie from "react-lottie";

// Json Files
import fuelling1 from "../../../assets/images/json/fuelling1.json";
import fuelling2 from "../../../assets/portfolio/fuilling/test/a2.png";
import fuelling3 from "../../../assets/images/json/fuelling3.json";
import fuelling4 from "../../../assets/images/json/fuelling4.json";
import fuelling5 from "../../../assets/images/json/fuelling5.json";
import video6 from "../../../assets/portfolio/fuilling/new/fuelling6.mp4";

// Animation
import gridA2 from "../../../assets/portfolio/fuilling/new/grid2icon.png";

// Styles
import "./styles.scss";

function FuellingImagination(props) {
  const cardList = [
    {
      jsonFile: fuelling1,
      jsonData: true,
    },
    {
      Background: fuelling2,
      animationImage: gridA2,
      imageActive: true,
    },
    {
      jsonFile: fuelling3,
      jsonData: true,
    },
    {
      jsonFile: fuelling4,
      jsonData: true,
    },
    {
      jsonFile: fuelling5,
      jsonData: true,
    },
    {
      videoUrl: video6,
      videoActive: true,
    },
  ];

  return (
    <div className={`fuelling-imagination-style ${props.classContainer}`}>
      <div className="inner-section">
        <div className="container">
          <div className="row mb--60">
            <div className="col-sm-12 text-center">
              <h1 className="title">
                Fuelling the <br /> imagination of millions
              </h1>
              <div className="about-text">
                We build products that mitigate the friction from everyday lives
                and inspire people to do more.
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="grid-style">
                {cardList.map((data) => (
                  <div className="item" data-aos-once="true" data-aos="zoom-in">
                    {data.imageActive === true && (
                      <div
                        style={{ backgroundImage: `url(${data.Background})` }}
                        className="image-item"
                      >
                        <div className="flex-image">
                          <img
                            src={data.animationImage}
                            alt="animation Image"
                            className="animation-image"
                          />
                          <img
                            src={data.animationImage}
                            alt="animation Image"
                            className="animation-image image2"
                          />
                        </div>
                      </div>
                    )}

                    {data.videoActive === true && (
                      <div className="flex-image">
                        <video playsInline autoPlay muted loop>
                          <source src={data.videoUrl} type="video/mp4" />
                        </video>
                      </div>
                    )}
                    <div className="title">{data.title}</div>

                    {data.jsonData === true && (
                      <div className="json-style">
                        <Lottie
                          speed={data.speed}
                          options={{
                            animationData: data.jsonFile,
                            loop: true,
                            autoplay: true,
                            rendererSettings: {
                              preserveAspectRatio: "xMidYMid slice",
                            },
                          }}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="achievement-sec">
            <div className="container">
              <div className="row">
                <div className="col-4 col-sm-4">
                  <div className="achievement-box">
                    <h3 className="title-lg">400M+</h3>
                    <div className="info-text">Users Engaged</div>
                    <div className="img1" />
                  </div>
                </div>
                <div className="col-4 col-sm-4">
                  <div className="achievement-box">
                    <h3 className="title-lg">1B+</h3>
                    <div className="info-text">Monthly Minutes</div>
                    <div className="img2" />
                  </div>
                </div>
                <div className="col-4 col-sm-4">
                  <div className="achievement-box">
                    <h3 className="title-lg">100+</h3>
                    <div className="info-text"> Countries Reached</div>
                    <div className="img3" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FuellingImagination;
