import Flickity from "react-flickity-component";
import "./styles.scss"

function CardSlider(props) {
  return (
    <div className={`primary-slider-container ${props.classContainer}`}>
      <Flickity
        className={`carousel outline-none ${props.className}`}
        elementType={"div"}
        options={props.options}
        disableImagesLoaded={false}
        reloadOnUpdate
        // static
      >
        {props.children}
      </Flickity>
    </div>
  );
}

export default CardSlider;
