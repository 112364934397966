import { Link } from 'react-router-dom';
// Images
import messangerpro from '../../../assets/images/product/Center.png';
import fimage from '../../../assets/images/product/Right_1.png';
import simage from '../../../assets/images/product/Right_2.png';
import timage from '../../../assets/images/product/Left_1.png';
import foimage from '../../../assets/images/product/Left_2.png';

// styles
import "./jointeam.scss";

const JoinTeam = (props) => {

  return (
    <div className="container-fluid JoinTeam">
      <div className="introtext">
        <div className="title">
          Join Our Team 
          {/* <span>dreamers and achievers</span> */}
        </div>
        <div className="content">
        We are looking for smart, passionate folks to join our team of smart go-getters and passionate do-gooders.
        </div>
      </div>
      <div className="images">
        <img src={messangerpro} alt="" className="centerimage" />
        <img src={fimage} className="fimage" alt="fimage" />
        <img src={simage} className="simage" alt="simage" />
        <img src={timage} className="timage" alt="timage" />
        <img src={foimage} className="foimage" alt="foimage" />
      </div>
      <Link to="/careers" className="joinnowbtn">
        Join Us
      </Link>
    </div>

  );
};

export default JoinTeam;
