import fuillingImage from "../../../assets/images/investment-page/fuilling.svg";

import image1 from "../../../assets/images/investment-page/f1.svg";
import image2 from "../../../assets/images/investment-page/f2.svg";
import image3 from "../../../assets/images/investment-page/f3.svg";

import Lottie from "react-lottie";
import processJson from "../../../assets/images/json/process.json";

import "./styles.scss";

function FuellingSection(props) {
  return (
    <div className={`fuelling-section-style ${props.classContainer}`}>
      <div className="container">
        <div className="row">
          <div className="col-sm-6">
            <Lottie
              options={{
                animationData: processJson,
                loop: true,
                autoplay: true,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
            />
            {/* <img src={fuillingImage} className="image-first" /> */}
          </div>

          <div className="col-sm-6">
            <h2 className="title">The Process</h2>
            <div className="box box1">
              <div className="icon-box">
                <img src={image1} alt="image icon" />
              </div>
              <div>
                <h4 className="title-sm">The What</h4>
                <p className="description">
                  Wherever you are in your journey, we provide the seed capital,
                  a specialised team with deep technical expertise, and a vast
                  network of advisors, domain experts, and technology leaders to
                  realise your boldest visions.
                </p>
              </div>
            </div>

            <div className="box box2">
              <div className="icon-box">
                <img src={image2} alt="image icon"  />
              </div>
              <div>
                <h4 className="title-sm">The How</h4>
                <p className="description">
                  We back founders at day 0. It’s never too early for us.
                  Through our uniquely transparent and efficient process, we
                  make investment decisions in under two weeks. Because when
                  you're a startup, time is (literally) money.
                </p>
              </div>
            </div>

            <div className="box box3">
              <div className="icon-box">
                <img src={image3} alt="image icon"  />
              </div>
              <div>
                <h4 className="title-sm">The Who</h4>
                <p className="description">
                  Investing early is about investing in people. We back the
                  fearless, the rule breakers, the rogue dreamers. We invest in
                  founders with a bold mission in a big market. And we don’t shy
                  away from underserved or unexplored markets.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FuellingSection;
