import React from "react";

// sections
import TopBanner from "../../components/investement/topBanner";
import WhatWeDo from "../../components/investement/whatWeDo";
import RatingCounter from "../../components/investement/ratingCounter";
import OurPortfolio from "../../components/investement/ourPortfolio";
import PhilosophySection from "../../components/investement/philosophySection";
import IndependentSection from "../../components/investement/independentSection";
import FuellingSection from "../../components/investement/fuellingSection";
import PeopleSection from "../../components/investement/peopleSection";
import OurWordSection from "../../components/investement/ourWordSection";
import MeetTeam from "../../components/investement/meetTeam";
import JoinUs from "../../components/joinUs";
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";

// Styles
import "./investement.scss";
import HelmetCustom from "../../components/helmetCustom";

// array of items for rating counter 1 section
const ratingCounterItems_1 = [
  {
    image: "",
    title: {
      primary: "50",
      secondary: "+",
    },
    subtitle: "Startups supported",
  },
  {
    image: "",
    title: {
      primary: "27",
      secondary: "",
    },
    subtitle: "Startups supported",
  },
  {
    image: "",
    title: {
      primary: "8",
      secondary: "mn+",
    },
    subtitle: "Startups supported",
  },
  {
    image: "",
    title: {
      primary: "40",
      secondary: "mn+",
    },
    subtitle: "Startups supported",
  },
];

const Investment = () => {
  return (
    <>
      <HelmetCustom title="Investment" metaDescription="Imagine a world where living is simple, decluttered and enriching. We are creating that reality, one app at a time!" />
      <div className="investement-root">
        <Navbar theme="theme-light" />

        <div>
          <TopBanner />
        </div>

        <div id="thesis">
          <WhatWeDo />
        </div>

        <div>
          <RatingCounter
            items={ratingCounterItems_1}
          />
        </div>

        <div id="portfolio">
          <OurPortfolio />
        </div>

        <section id="philosophy">
          <PhilosophySection />
        </section>

        <section>
          <IndependentSection />
        </section>

        <section id="process">
          <FuellingSection />
        </section>

        <section id="partners">
          <PeopleSection />
        </section>

        <section>
          <OurWordSection />
        </section>

        <section>
          <MeetTeam />
        </section>

        <section>
          <JoinUs btnName={"Apply Now"} href="/contact">
            <h4 className="title">Want to build your company with us?</h4>
            <p className="description">
              We’ll work as a single tactical unit across each critical stage.
              Companies that receive an investment from us get full support from
              our entire team.
            </p>
          </JoinUs>
        </section>

        <section>
          <Footer />
        </section>
      </div>
    </>
  );
};

export default Investment;
