// Images
import { Link } from "react-router-dom";
import arrowRight from "../../assets/icons/right-arrow.svg"

// Styles
import "./styles.scss";

function JoinUs(props) {

  return (
    <div className={`join-us-style ${props.classContainer}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-sm-8">{props.children}</div>
          <div className="col-sm-4 join-button-section">
            <a className="join-us-btn" target={props.target} href={props.href}>{props.btnName} <img src={arrowRight} className="icon"/></a>
            {/* <button  id="joinButton">
              
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default JoinUs;
