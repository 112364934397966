// styles
import "./perkssec.scss";

const PerksSec = (props) => {

  return (
    <div
      className="container-fluid perkparent">
      <div className="perk_g_box">
        <div className="content">
          We show our gratitude in the big ways and the small ones. So that you feel creatively energized when you're here and have time to live your life.
        </div>
        <div className="title">
          Perks and benefits
        </div>
      </div>
      <div className="contentbox">
        <div>
          Extraordinary bonus structure inclusive of not just the company's but our entire investment portfolio's performance
        </div>
        <div>You can't change the world on an empty stomach. Your breakfast is on us! </div>
        <div>Flexible work schedules because we appreciate diversity in all forms, even in working styles</div>
        <div>Professional development and learning opportunities through expert sessions and training modules</div>
        <div>Stock options, health insurance and more perks in store because we know commitment is a two-way street </div>
        <div>We see possibilities in everything and everyone, so growth is not an option but a promise</div>
      </div>
      <div className="seeyourself">
        <div className="title">
          See yourself here?
        </div>
        <div className="content">
          Drop us your resume at careers@appyhigh.com
        </div>
      </div>
    </div>

  );
};

export default PerksSec;
