import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Lmoreico from '../../../assets/icons/carrer_lmore.png';

// Styles
import "./openPositions.scss";

const OpenPositions = (props) => {
    const [showMore, setShowMore] = useState(false);
    const numberOfItems = showMore ? props.postsToRender.length : 5;
    return (
        <>
            {
                props.postsToRender.slice(0, numberOfItems).map((item, i) =>
                    <Link className={`link-section ${item.tag}`} to={{ pathname: `/careers/${item.link}`, careerprops: { name: item } }}>
                        <div className="positions_parent">
                            <div>
                                <div className="title">{item.title}</div>
                                <div className="content">
                                    <div className="text">
                                        {item.text}
                                    </div>
                                </div>
                            </div>
                            <div className="link">
                                <img src={Lmoreico} alt="more icon" /> <span>Learn More</span>
                            </div>
                        </div>
                    </Link>
                )
            }
            {props.postsToRender.length > numberOfItems ?
                <div className="showmorebtn" onClick={() => setShowMore(!showMore)}>
                    Show {showMore ? "Less" : "More"}
                </div> : null}
        </>
    );
}

export default OpenPositions;