// video file
import { useEffect } from "react";
import introVideo1 from "../../../assets/videos/home-intro1.mp4";

// styles
import "./styles.scss";

function IntroFirst(props) {
  useEffect(() => {
    var vid = document.querySelector(".home-video-section");
    vid.className = "home-video-section loading";
    document
      .getElementById("team-video")
      .addEventListener("loadstart", () => { });
    document
      .getElementById("team-video")
      .addEventListener("canplaythrough", () => {
        vid.className = "home-video-section";
      });
  }, []);
  return (
    <>
      <div id="introAnimation" className="intro-main-section">
        <div id="slide01" className="slide01">
          <div className="background-container">
            <div className="home-video-section">
              <video
                className="introVideo"
                playsInline
                autoPlay
                muted
                loop
                id="team-video"
              >
                <source src={introVideo1} type="video/mp4" />
              </video>
            </div>
          </div>
          <div className="content-box">
            <p className="decription">
              Imagine a world where living is <br /> simple, decluttered and
              <br /> enriching. We are creating that <br /> reality, one app at
              a time!
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default IntroFirst;
