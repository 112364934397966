import "./styles.scss";

const LogoGrid = (props) => {
  return (
    <div class="app-logos-container">
      <p className="grid-title">{props.title}</p>
      <div className="logo-grid">
        {props.logos.map((logo) => {
          return (
            <div className="icon-container">
              <img src={logo.url} className="app-icon" alt="appyhigh" />
              <p className="item-color">{logo.name}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LogoGrid;
