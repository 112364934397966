import {Link} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import Lottie from "react-lottie";
import shape1 from "../../../assets/images/json/founders.json";
import shape2 from "../../../assets/images/json/whatWeDo.json";
import shape3 from "../../../assets/images/json/smartPatient.json";

import "./styles.scss";

const whatWeDoItems = [
  {
    jsonUrl: shape1,
    title: "The Founder's Ally",
    subtitle: "Fully in your corner",
    text: "We're founders too. So that's our DNA. We've grown and exited several companies and hence know what it takes. We roll up our sleeves and figure stuff out – everything – together with you. ",
    widthStyle: "190px",
    heightStyle: "200px",
  },
  {
    jsonUrl: shape2,
    title: "Real, Solid Guidance",
    subtitle: "That crushes targets",
    text: "Once you’ve hit the ground running, our specialists and network experts are by your side to help with hires, strategic decisions, product-specs, communication questions, and more.",
    widthStyle: "210px",
    heightStyle: "200px",
  },
  {
    jsonUrl: shape3,
    title: "Smart, Patient Capital",
    subtitle: "In it for the journey",
    text: "We're not your typical VC. We're committed and  have a long-term goal. From our own funds to accessing world-class investors, our portfolio companies raise bigger rounds faster, with better terms.",
    widthStyle: "210px",
    heightStyle: "200px",
  },
];

const settings = {
  dots: false,
  speed: 500,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 3,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
        variableWidth: false,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000
      },
    },
  ],
};

const WhatWeDo = (props) => {
  return (
    <div className="whatWeDo-root container-fluid">
      <div className="container">
        <div className="cards-row">
        <Slider {...settings}>
          {whatWeDoItems.map((data) => {
            return (
              <div className="cards">
                <Lottie
                  height={data.heightStyle}
                  width={data.widthStyle}
                  options={{
                    animationData: data.jsonUrl,
                    loop: true,
                    autoplay: true,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                />
                <h3 className="text-center font-weight-bold titleh3">
                  {data.title}
                </h3>
                <div className="subtitle">{data.subtitle}</div>
                <p className="text-center description">{data.text}</p>
              </div>
            );
          })}
          </Slider>
        </div>
        <div className="bottom-button row">
          <div className="col-12 d-flex place-items-center justify-content-center">
            <Link to="/contact">Connect Now</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
