import React from "react";
import HelmetCustom from "../../components/helmetCustom";

// sections
import HeroSec from "../../components/product/hero";
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import Whowe from "../../components/product/whowe";
import Portfolio from "../../components/product/portfolio";
import JoinTeam from "../../components/product/jointeam";

// Styles
import "./product.scss";

const Product = () => {
  return (
    <>
      <HelmetCustom title="Our Products" metaDescription="Imagine a world where living is simple, decluttered and enriching. We are creating that reality, one app at a time!" />
      <div className="product-root">
        <Navbar />

        <div>
          <HeroSec />
        </div>

        <div id="Whowe">
          <Whowe />
        </div>

        <div id="Portfolio">
          <Portfolio />
        </div>

        <div id="JoinTeam">
          <JoinTeam />
        </div>

        <section>
          <Footer />
        </section>
      </div>
    </>
  );
};

export default Product;
