// Images
import bannerImage from "../../../assets/images/product-banner.png";

// styles
import "./productmain.scss";

const HeroSec = (props) => {
  return (
    <div className="container-fluid productbanner hero" id="hero">
      <div className="box_main">
        <div className="banner-section">
          <img src={bannerImage}  alt="banner image"/>
          <div className="hero_text">
            <div className="pagetitle">Products</div>
            <h1 className="headtitle">Human-Driven.<br/> Technology-Powered.</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSec;
