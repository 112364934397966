import { useEffect } from "react";
import videoAbout from "../../../assets/videos/about.mp4";
import aboutBg from "../../../assets/images/about-bg.png";
// styles
import "./aboutmain.scss";

const HeroSec = (props) => {
  useEffect(() => {
    var vid = document.querySelector(".about-video-section");
    vid.className = "about-video-section";
    document
      .getElementById("aboutVideo")
      .addEventListener("loadstart", () => { });
    document
      .getElementById("aboutVideo")
      .addEventListener("canplaythrough", () => {
        vid.className = "about-video-section";
      });
  }, []);

  return (
    <div className="aboutbanner" id="hero">
      <div className="about-video-section">
        <video playsInline autoPlay muted loop id="aboutVideo" poster={aboutBg}>
          <source src={videoAbout} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <div className="text-section">
        <div className="container" id="container">
          <div className="hero-text">
            <div className="pagetitle">ABOUT US</div>
            <h1 className="headtitle">
              Technology for a <br />
              simpler, better tomorrow.
            </h1>
            <div className="headcontent">
              We are all about making a small difference in the daily lives of
              millions..in simple, entertaining and elating ways!
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSec;
