import React, { useState } from "react";


import { useSwipeable } from "react-swipeable";

// styles
import "./storyboard.scss";

const Storyboard = (props) => {

  const [currentIndex, setIndex] = useState(9);
  var scroll = 0;
  setTimeout(function () {
    nextactive(currentIndex);
    if (window.innerWidth < 767) {
      var scrollactive = document.getElementById('storyboardslider').children[9];
      document.getElementById('storyboardslider').scrollLeft = scrollactive.offsetLeft;
      scroll = document.getElementById('storyboardslider').childNodes[9].offsetWidth * 9;
    }
  });


  const handlers = useSwipeable({
    preventDefaultTouchmoveEvent: true,
    onSwiped: (eventData) => {
      var scrollable = document.getElementById('storyboardslider');
      if (window.innerWidth < 767) {
        if (eventData.dir === 'Left') {
          // debugger;
          if (scroll < scrollable.scrollWidth - document.getElementById('storyboardslider').childNodes[0].offsetWidth) {
            scroll = scroll + document.getElementById('storyboardslider').childNodes[0].offsetWidth;
          }
          scrollable.scroll({
            left: scroll,
            top: 0,
            behavior: 'smooth'
          })
        }
        if (eventData.dir === 'Right') {
          if (scroll !== 0) {
            scroll = scroll - document.getElementById('storyboardslider').childNodes[0].offsetWidth;;
          }
          scrollable.scroll({
            left: scroll,
            top: 0,
            behavior: 'smooth'
          })
        }

      }
    }
  });
  const nextactive = (index) => {
    var scrollable = document.getElementById('storyboardslider');
    if (window.innerWidth > 767) {
      if (index > 2) {
        scroll = index * 140;
        scrollable.scroll({
          left: scroll,
          top: 0,
          behavior: 'smooth'
        })
      }
      else {
        scrollable.scroll({
          left: 0,
          top: 0,
          behavior: 'smooth'
        })
      }
    }
  }



  return (
    <div className="Storyboardsec">
      <div className="heading">
        Our Journey
      </div>
      <div id="storyboardslider" className="storyboard_slider_parent" style={{ touchAction: 'pan-y' }} {...handlers}>
        {props.items.map((item, index) =>
          <div key={index} className={currentIndex === index ? "active" : ''} {...nextactive} onClick={() => { setIndex(index); }}>
            <div className="contentbox">
              <div className="date"><span>{item.date}</span></div>
              <div className="text">
                <div className="innertext">
                  {item.text}
                </div>
                {item.milestone ?
                  <div className="milestones">
                    {item.milestone}
                  </div>
                  : null}
              </div>
            </div>
          </div>
        )
        }
      </div>
    </div>

  );
};

export default Storyboard;
