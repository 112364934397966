// images
import scroll_mouse_icon from "../../../assets/images/investment-page/scroll-mouse-icon.svg";
import box1 from "../../../assets/images/carrers/box1.png";
import box2 from "../../../assets/images/carrers/box2.png";
import box3 from "../../../assets/images/carrers/box3.png";
import box4 from "../../../assets/images/carrers/box4.png";
import box5 from "../../../assets/images/carrers/box5.png";
import box6 from "../../../assets/images/carrers/box6.png";
import box7 from "../../../assets/images/carrers/box7.png";
import box8 from "../../../assets/images/carrers/box8.png";

// styles
import "./styles.scss";

import {useSwipeable} from "react-swipeable";

const HeroSec = (props) => {
  const handleCursor = (event) => {
    if (window.innerWidth > 767) {
      if (event) {
        var hero = document.getElementById("container");
        if (event.pageY < window.innerHeight / 2) {
          if (event.pageX < window.innerWidth / 2) {
            hero.style.transform = "translate(-30%, -30%) scale(1)";
          }
          if (event.pageX > window.innerWidth / 2) {
            hero.style.transform = "translate(-70%, -30%) scale(1)";
          }
        }
        if (event.pageY > window.innerHeight / 2) {
          if (event.pageX < window.innerWidth / 2) {
            hero.style.transform = "translate(-30%, -82%) scale(1)";
          }
          if (event.pageX > window.innerWidth / 2) {
            hero.style.transform = "translate(-70%, -82%) scale(1)";
          }
        }
      }
    }
  };

  const leaveHero = (event) => {
    if (event) {
      var mousePointer = document.getElementById("container");
      mousePointer.style.transform = "translate(-50%, -50%) scale(1)";
    }
  };

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      var hero = document.getElementById("container");
      if (window.innerWidth < 767) {
        if (eventData.dir === "Left") {
          hero.style.transform = "translate(-85%, -45%) scale(1)";
          setTimeout(() => {
            document.querySelector(".box4").style.top = "304px";
          }, 1300);
        }
        if (eventData.dir === "Right") {
          hero.style.transform = "translate(-20%, -50%) scale(1)";
        }
        if (eventData.dir === "Up") {
          hero.style.transform = "translate(-50%, -60%) scale(1)";
        }
        if (eventData.dir === "Down") {
          hero.style.transform = "translate(-60%, -21%) scale(1)";
        }
      }
    },
  });

  const showonmobile = (event) => {
    var text = event.target.parentElement.querySelector(".text").innerText;
    document.getElementById("mobile_inner_text").children[1].innerText = "";
    document.getElementById("mobile_inner_text").classList.add("show");
    document.getElementById("mobile_inner_text").children[1].innerText = text;
  };
  const hidenmobile = (event) => {
    document.getElementById("mobile_inner_text").children[1].innerText = "";
    document.getElementById("mobile_inner_text").classList.remove("show");
  };

  return (
    <div
      className="container-fluid top-banner hero"
      id="hero"
      {...handlers}
      onMouseMove={(ev) => handleCursor(ev)}
      onMouseLeave={(ev) => leaveHero(ev)}
    >
      <div className="container" id="container">
        <div className="hero_text">
          <div className="pagetitle">Careers</div>
          <h1 className="headtitle">
            Success feels <br />best when it's shared
          </h1>
          <div className="headcontent">
            There is immense power in collaboration because tough times don't
            last long, but tough teams do!
          </div>
        </div>
        <div className="box box1">
          <img src={box1} alt="box1" />
          <div className="text" onClick={(ev) => showonmobile(ev)}>
            Only a bunch of passionate dreamers can take a crazy vision and turn
            it into reality
          </div>
        </div>
        <div className="box box2">
          <img src={box2} alt="box2" />
          <div className="text">
            If we can laugh together, we can work together
          </div>
        </div>
        <div className="box box3">
          <img src={box3} alt="box3" />
          <div className="text">
            In the midst of chaos, there is also opportunity
          </div>
        </div>
        <div className="box box4">
          <img src={box4} alt="box4" />
          <div className="text">
            The nice thing about team work is you always have others on your
            side
          </div>
        </div>
        <div className="box box5">
          <div className="text">Simply stated, it's less me and more we</div>
          <img src={box5} alt="box5" />
        </div>
        <div className="box box6">
          <div className="text">
            When we hand good people possibilities, they do great things
          </div>
          <img src={box6} alt="box6" />
        </div>
        <div className="box box7">
          <div className="text">Many hands make the work light</div>
          <img src={box7} alt="box7" />
        </div>
        <div className="box box8">
          <div className="text">
            "Never doubt that a small group of thoughtful, committed people can
            change the world. Indeed. It is the only thing that ever has."
          </div>
          <img src={box8} alt="box8" />
        </div>
      </div>
      <div className="mobile_inner_text" id="mobile_inner_text">
        <div className="cross" onClick={(ev) => hidenmobile(ev)}></div>
        <div></div>
      </div>
    </div>
  );
};

export default HeroSec;
