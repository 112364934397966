import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import ButtonDark from "../../buttons/buttonDark";

// Images
import img1 from "../../../assets/images/home/what-makes/a1.svg";
import img2 from "../../../assets/images/home/what-makes/a2.svg";
import img3 from "../../../assets/images/home/what-makes/a3.png";
import img4 from "../../../assets/images/home/what-makes/a4.svg";

// Styles
import "./styles.scss";
import { Link } from "react-router-dom";

function WhatMakes(props) {
  const history = useHistory();
  const handleOnClick = useCallback(() => history.push('/sample'), [history]);

  return (
    <div className={`what-makes-style ${props.classContainer}`}>
      <div className="inner-section">
        <div className="container">
          <div className="row">
            <div className="col-sm-7">
              <div className="content-section">
                <h3 className="title">
                  What makes <br /> us click?
                </h3>
                <div className="about-text">
                  We’re big on
                  experimenting early and often, thrive on synergies, and most
                  of all - we look at technology through the lens of human
                  nature.
                </div>
                <Link to="/careers" className="career-btn">View more</Link>
              </div>
            </div>
            <div className="col-sm-5 grid-section">
              <div className="grid-style">
                <img
                  src={img1}
                  className="img-card"
                  data-aos-once="true"
                  data-aos="zoom-in"
                  alt="image card"
                />
                <img
                  src={img2}
                  className="img-card"
                  data-aos-once="true"
                  data-aos="zoom-in"
                  alt="image card"
                />
                <img
                  src={img3}
                  className="img-card"
                  data-aos-once="true"
                  data-aos="zoom-in"
                  alt="image card"
                />
                <img
                  src={img4}
                  className="img-card"
                  data-aos-once="true"
                  data-aos="zoom-in"
                  alt="image card"
                />
              </div>

              <ButtonDark
                btnStyle="d-sm-none"
                color="black"
                title="View more"
                onClick={handleOnClick}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhatMakes;
