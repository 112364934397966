// Images
import map from "../../../assets/images/contact/map.svg";
import { Link } from "react-router-dom";

// Styles
import "./WherweWork.scss";

function WherweWork() {
  return (
    <div className="wherweWork">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-sm-6">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.3409506804323!2d77.06595367414181!3d28.40896724070601!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d226f2a83c6cb%3A0x10bd8697dcde3a02!2sSuncity%20Success%20Tower%2C%20Sector%2065%2C%20Gurugram%2C%20Haryana%20122102!5e0!3m2!1sen!2sin!4v1681722062396!5m2!1sen!2sin"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
          <div className="col-sm-6">
            <div className="descarea">
              <div className="title">Where we work</div>
              <div className="content">
                125-128, 1st Floor, Suncity Success Tower, Golf Course Extension
                Road, Sector 65, Gurgaon, Haryana, 122018
              </div>
              <a
                href="http://maps.google.com/maps?q=125-128, 1st Floor, Suncity Success Tower, Golf course extn road, Sector 65, Gurgaon Haryana 122018"
                target="_blank"
              >
                Get Direction
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WherweWork;
