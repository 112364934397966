import React, { useEffect } from "react";
// Animation
import * as ScrollMagic from "scrollmagic";

import Culturebg1 from "../../../assets/images/carrers/culturebg1.png";
import Culturebg2 from "../../../assets/images/carrers/culturebg2.png";
import Culturebg3 from "../../../assets/images/carrers/culturebg3.png";
import Culturebg4 from "../../../assets/images/carrers/culturebg4.png";
import deepbooted from "../../../assets/images/carrers/deepbooted.png";
import bigbold from "../../../assets/images/carrers/bigbold.png";
import lovefor from "../../../assets/images/carrers/lovefor.png";
import userbas from "../../../assets/images/carrers/userbas.png";

// Styles
import "./writetoUs.scss";

const WritetoUs = (props) => {
  return (
    <div className="writetoUs">
      <div className="culture_inner">
        <div className="titlearea">
          <div className="title">Write to us</div>
          <div className="text">
            In this hyper-competitive and
            cluttered market, we are uniquely placed to build products that
            speak directly to the immediate needs of people.
          </div>
        </div>
        <div className="boxes_parent">
          <div className="container">
            <div className="row">
              <div className="second box col-sm-4">
                <div className="title">Media Enquiry</div>
                <div className="text">
                  <a href="mailto:media@appyhigh.com">media@appyhigh.com</a>
                </div>
                <div className="anim-box">
                  <img src={Culturebg2} className="fbg animated-image" alt="Media Enquiry" />
                </div>
              </div>
              <div className="second box col-sm-4">
                <div className="title">Advertising and Agency Enquiry</div>
                <div className="text">
                  <a href="mailto:advertisers@appyhigh.com">advertisers@appyhigh.com</a>
                </div>
                <div className="anim-box">
                  <img src={Culturebg1} className="sbg animated-image" alt="Advertising and Agency" />
                </div>
              </div>
              <div className="second box col-sm-4">
                <div className="title">Publisher Enquiry</div>
                <div className="text">
                  <a href="mailto:partnerwithus@appyhigh.com">partnerwithus@appyhigh.com</a>
                </div>
                <div className="anim-box">
                  <img src={Culturebg2} className="tbg animated-image" alt="Publisher Enquiry" />
                </div>
              </div>
              <div className="second box col-sm-4">
                <div className="title">Investor Relations Enquiry</div>
                <div className="text">
                  <a href="mailto:ventures@appyhigh.com">ventures@appyhigh.com</a>
                </div>
                <div className="anim-box">
                  <img src={Culturebg1} className="fobg animated-image" alt="Investor Relations" />
                </div>
              </div>
              <div className="second box col-sm-4">
                <div className="title">Careers Relations Enquiry</div>
                <div className="text">
                  <a href="mailto:careers@appyhigh.com">careers@appyhigh.com</a>
                </div>
                <div className="anim-box">
                  <img src={Culturebg2} className="fifbg animated-image" alt="Careers Relations" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default WritetoUs;
