import "./styles.scss";

function PolicyDetails(props) {
  return (
    <div className={`fuelling-imagination-style ${props.classContainer}`}>
      <div className="inner-section">
        <div className="container">
          <div className="row mb--60">
            <div>
              <p>
                At appyhigh.com, accessible from www.appyhigh.com, one of our
                main priorities is the privacy of our visitors. This Privacy
                Policy document contains types of information that is collected
                and recorded by appyhigh.com and how we use it. If you have
                additional questions or require more information about our
                Privacy Policy, do not hesitate to contact us. This Privacy
                Policy applies only to our online activities and is valid for
                visitors to our website with regards to the information that
                they shared and/or collect in appyhigh.com. This policy is not
                applicable to any information collected offline or via channels
                other than this website. Our Privacy Policy was created with the
                help of the Privacy Policy Generator.
              </p>
              <br />
              <p className="title">Consent</p>
              <p>
                By using our website, you hereby consent to our Privacy Policy
                and agree to its terms.
              </p>
              <p className="title">Information we collect</p>
              <p>
                The personal information that you are asked to provide, and the
                reasons why you are asked to provide it, will be made clear to
                you at the point we ask you to provide your personal
                information. If you contact us directly, we may receive
                additional information about you such as your name, email
                address, phone number, location, the contents of the message
                and/or attachments you may send us, and any other information
                you may choose to provide. When you register for an Account, we
                may ask for your contact information, including items such as
                name, company name, address, email address, and telephone
                number.
              </p>

              <br />
              <p className="title">How we use your information</p>
              <p>
                <ul>
                  We use the information we collect in various ways, including
                  to:
                  <li>Provide, operate, and maintain our website</li>
                  <li>Improve, personalize, and expand our website </li>
                  <li>Understand and analyze how you use our website</li>
                  <li>
                    Develop new products, services, features, and functionality
                  </li>
                  <li>
                    Communicate with you, either directly or through one of our
                    partners, including for customer service, to provide you
                    with updates and other information relating to the website,
                    and for marketing and promotional purposes
                  </li>
                  <li>Send you emails Find and prevent fraud</li>
                </ul>
              </p>

              <br />
              <p className="title">Log Files</p>
              <p>
                appyhigh.com follows a standard procedure of using log files.
                These files log visitors when they visit websites. All hosting
                companies do this as part of hosting services' analytics. The
                information collected by log files include internet protocol
                (IP) addresses, browser type, Internet Service Provider (ISP),
                date and time stamp, referring/exit pages, and possibly the
                number of clicks. These are not linked to any information that
                is personally identifiable. The purpose of the information is
                for analyzing trends, administering the site, tracking users'
                movement on the website, and gathering demographic information.
              </p>

              <br />
              <p className="title">Cookies and Web Beacons</p>
              <p>
                Like any other website, appyhigh.com uses 'cookies'. These
                cookies are used to store information including visitors'
                preferences, and the pages on the website that the visitor
                accessed or visited. The information is used to optimize the
                users' experience by customizing our web page content based on
                visitors' browser type and/or other information. For more
                general information on cookies, please read "What Are Cookies".
              </p>

              <br />
              <p className="title">Advertising Partners Privacy Policies</p>
              <p>
                You may conslit this list to find the Privacy Policy for each of
                the advertising partners of appyhigh.com. Third-party ad servers
                or ad networks use technologies like cookies, JavaScript, or Web
                Beacons that are used in their respective advertisements and
                links that appear on appyhigh.com, which are sent directly to
                users' browsers. They automatically receive your IP address when
                this occurs. These technologies are used to measure the
                effectiveness of their advertising campaigns and/or to
                personalize the advertising content that you see on websites
                that you visit. Note that appyhigh.com has no access to or
                control over these cookies that are used by third-party
                advertisers.
              </p>

              <br />
              <p className="title">Third Party Privacy Policies</p>
              <p>
                appyhigh.com's Privacy Policy does not apply to other
                advertisers or websites. Thus, we are advising you to conslit
                the respective Privacy Policies of these third-party ad servers
                for more detailed information. It may include their practices
                and instructions about how to opt-out of certain options. You
                can choose to disable cookies through your individual browser
                options. To know more detailed information about cookie
                management with specific web browsers, it can be found at the
                browsers' respective websites.
              </p>

              <br />
              <p className="title">Data Location</p>
              <p>
                We may in the course of providing Services to you and/or in
                using services from third parties as mentioned elsewhere,
                transfer, store or process the information in different
                locations, including locations outside the territory of India.
                By using our services, you consent to transfer your information
                to such different or foreign locations.
              </p>

              <br />
              <p className="title">Changes to this Policy</p>
              <p>
                This Privacy Policy may be updated from time to time for any
                reason. It is the responsibility of all users of the Services to
                visit this page to check for updates. By visiting, accessing
                and/or using any of the Services, you consent to the terms of
                this Privacy Policy.
              </p>

              <br />
              <p className="title">Contacting Us</p>
              <p>
                You may write to us at finance@appyhigh.com for
                queries/clarifications regarding our Privacy Policy.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PolicyDetails;
