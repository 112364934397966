import React, { useEffect, useState } from "react";
import HelmetCustom from "../../components/helmetCustom";
// sections
import HeroSec from "../../components/contact/hero";
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import OpenPositions from "../../components/careers/openPositions";
import WritetoUs from "../../components/contact/writetoUs";
import WherweWork from "../../components/contact/whereweWork";
// Styles
import "./contact.scss";

// array of items for Open position section


const Contact = () => {
  return (
    <>
      <HelmetCustom title="Contact Us" metaDescription="Imagine a world where living is simple, decluttered and enriching. We are creating that reality, one app at a time!" />
      <div className="contact-root">
        <Navbar />

        <div className="">
          <HeroSec />
        </div>


        <div id="culture" className=" culturesec">
          <WritetoUs />
        </div>
        <div id="culture" className="">
          <WherweWork />
        </div>
        <section>
          <Footer pagename={"Careers"} />
        </section>
      </div>
    </>
  );
};

export default Contact;
