// Images
import logo1 from "../../../assets/images/investment-page/clients/Partners_1.png";
import logo2 from "../../../assets/images/investment-page/clients/Partners_2.png";
import logo3 from "../../../assets/images/investment-page/clients/Partners_3.png";
import logo4 from "../../../assets/images/investment-page/clients/Partners_4.png";
import logo5 from "../../../assets/images/investment-page/clients/Partners_5.png";
import logo6 from "../../../assets/images/investment-page/clients/Partners_6.png";
import logo7 from "../../../assets/images/investment-page/clients/Partners_7.png";
import logo8 from "../../../assets/images/investment-page/clients/Partners_8.png";
import logo9 from "../../../assets/images/investment-page/clients/Partners_9.png";
import logo11 from "../../../assets/images/investment-page/clients/Partners_11.png";
import logo12 from "../../../assets/images/investment-page/clients/Partners_13.png";
import logo10 from "../../../assets/images/investment-page/our-portfolio/logo 57.png";

// Styles
import "./styles.scss";

function PeopleSection(props) {
  const clientLogo = [
    {
      imgUrl: logo1,
      title: "Influencer Marketing",
      logoStyle: "logo-small",
    },
    {
      imgUrl: logo2,
      title: "Chatbot Partner",
    },
    {
      imgUrl: logo3,
      title: "Infrastructure Partner",
    },
    {
      imgUrl: logo4,
      title: "SEO Partner",
    },
    {
      imgUrl: logo5,
      title: "Co-working Space",
    },
    {
      imgUrl: logo6,
      title: "App & Market Intelligence Partner",
      logoStyle: "logo-lg",
    },
    {
      imgUrl: logo7,
      title: "Co-working Space",
    },
    {
      imgUrl: logo8,
      title: "Micro-VC Network",
    },
    {
      imgUrl: logo9,
      title: "Product Experience",
    },
    {
      imgUrl: logo10,
      title: "Monetization Partner",
    },
    {
      imgUrl: logo11,
      title: "Tech Partner",
    },
    {
      imgUrl: logo12,
      title: "Angel Network",
    },
  ];
  return (
    <div className={`people-section-style ${props.classContainer}`}>
      <div className="inner-section">
        <h4 className="title">Partners who back AppyHigh </h4>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            {clientLogo.map((data, index) => (
              <div className="col-sm-3 col-6 box-col" key={index}>
                <div className={`logo-box ${data.logoStyle}`}>
                  <div className="img-sec">
                    <img src={data.imgUrl} alt="Partners who back AppyHigh" />
                  </div>
                  <p>{data.title}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PeopleSection;
