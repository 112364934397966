import React, { useEffect } from "react";
// Animation
import * as ScrollMagic from "scrollmagic";

import Culturebg1 from "../../../assets/images/carrers/culturebg1.png";
import Culturebg2 from "../../../assets/images/carrers/culturebg2.png";
import Culturebg3 from "../../../assets/images/carrers/culturebg3.png";
import Culturebg4 from "../../../assets/images/carrers/culturebg4.png";
import deepbooted from "../../../assets/images/carrers/deepbooted.png";
import bigbold from "../../../assets/images/carrers/bigbold.png";
import lovefor from "../../../assets/images/carrers/lovefor.png";
import userbas from "../../../assets/images/carrers/userbas.png";

// Styles
import "./ourCulture.scss";

const OurCulture = (props) => {
  const media = window.matchMedia(`(min-width: 768px)`);

  useEffect(() => {
    if (media.matches === true) {
      var cultureController = new ScrollMagic.Controller({
        globalSceneOptions: { duration: "100%", triggerHook: 0.2 },
      });
      new ScrollMagic.Scene({ triggerElement: ".culture-box" })
        .setClassToggle(".culture-box", "animationActive")
        .addTo(cultureController);
    }
  }, []);

  const gridImage = [
    {
      imgUrl: deepbooted,
    },
    {
      imgUrl: bigbold,
    },
    {
      imgUrl: lovefor,
    },
    {
      imgUrl: userbas,
    },
  ];

  return (
    <div className="ourcultureParent">
      <div className="culture_inner">
        <div className="titlearea">
          <div className="title">Our Culture</div>
          <div className="text">
            Our products are humane, fun and experimental <br />
            and so are we.
          </div>
        </div>
        <div className="boxes_parent">

          <div className="container">
            <div className="row">
              <div className="first box col-sm-6">
                <div className="title">Great vibe </div>
                <div className="text">
                  If you have been breaking stereotypes all your life, you are
                  already one of us. We believe that unique people make
                  groundbreaking products, so we let the quirk quotient run high. Be
                  it seasonal rappers, marathon runners, pet lovers, beer
                  connoisseurs, you name it and we have them all.
                </div>

                <img src={Culturebg1} className="fbg animated-image" alt="Great vibe" />
              </div>
              <div className="second box col-sm-6">
                <div className="title">A Growth mindset</div>
                <div className="text">
                  We belong to the 'talent over experience' school of thought. If
                  you have the hunger and grit to break conventional barriers and
                  discover unexplored avenues, we totally dig it.
                </div>
                <img src={Culturebg2} className="sbg animated-image"  alt="A Growth mindset" />
              </div>
              <div className="third box col-sm-6">
                <div className="title">Perks, perks everywhere</div>
                <div className="text">
                  From pizza parties to happy hours. From board games to team
                  retreats. We cover it all! On top of that, there's brag-worthy
                  company swag, and you even get to wear slippers to office.
                </div>

                <img src={Culturebg3} className="tbg animated-image" alt="Perks, perks everywhere" />
              </div>
              <div className="fourth box col-sm-6">
                <div className="title">Learn, Unlearn, Repeat</div>
                <div className="text">
                  Need another reason to love Fridays? It's Knowledge Cafe day,
                  where you can take away from the team's collective wisdom and
                  learn invaluable tricks of others' trades. And who knows, you
                  might just discover your dream profile in one of these?
                </div>
                <img src={Culturebg4} className="fobg animated-image" alt="Learn, Unlearn, Repeat"  />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="culture-box" id="pillars">
        <div className="culture_images_box">
          {gridImage.map((data) => {
            return (
              <div className="cultureimgpart">
                <img src={data.imgUrl} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default OurCulture;
