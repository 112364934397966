import {Link} from "react-router-dom";
import ButtonDark from "../../buttons/buttonDark";

// Images
import a1 from "../../../assets/icons/company/Cloudworx.png";
import a2 from "../../../assets/icons/company/HUVIAiR.png";
import a3 from "../../../assets/icons/company/JustBusinesscopy.png";
import a4 from "../../../assets/icons/company/Krishify.png";
import a5 from "../../../assets/icons/company/Localiti.png";
import a6 from "../../../assets/icons/company/Messengerx.png";
import a7 from "../../../assets/icons/company/Mysoho.png";
import a8 from "../../../assets/icons/company/Tryndbuy.png";

import b1 from "../../../assets/icons/company/agnikul.png";
import b2 from "../../../assets/icons/company/dukaan.png";
import b3 from "../../../assets/icons/company/Fitso.png";
import b4 from "../../../assets/icons/company/FLickstree.png";
import b5 from "../../../assets/icons/company/Onecode.png";
import b6 from "../../../assets/icons/company/Oneimpression.png";
import b7 from "../../../assets/icons/company/Pankhuri.png";
import b8 from "../../../assets/icons/company/Vernacularai.png";

// Styles
import "./styles.scss";

function BuildProducts(props) {
  const companyIconsA = [
    {imgIcon: a1},
    {imgIcon: a2},
    {imgIcon: a3},
    {imgIcon: a4},
    {imgIcon: a5},
    {imgIcon: a6},
    {imgIcon: a7},
    {imgIcon: a8},
  ];
  const companyIconsB = [
    {imgIcon: b1},
    {imgIcon: b2},
    {imgIcon: b3},
    {imgIcon: b4},
    {imgIcon: b5},
    {imgIcon: b6},
    {imgIcon: b7},
    {imgIcon: b8},
  ];
  return (
    <div className={`build-products-style ${props.classContainer}`}>
      <div className="inner-section">
        <div className="container">
          <div className="row mb--60">
            <div className="col-sm-12 text-center">
              <h2 className="title">We don’t just build products.</h2>
              <div className="about-text">
              We back early-stage startups with capital, resources and heart.
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="content-section right-section">
                <div className="index-style">
                  <div className="title-lg text-purple">Accelerate</div>
                  <p className="description">
                  We believe in sharing what we know, who we know and what we have.
                  </p>
                  <Link to="/investment" className="viewmore_co">View more</Link>
                </div>
                <div className="bg1" />
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="company-section">
                {companyIconsA.map((data) => {
                  return (
                    <div className="icon-box">
                      <img src={data.imgIcon} alt="icon" />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

        <div className="row row-column-change">
          <div className="col-12 col-sm-6">
            <div className="company-section">
              {companyIconsB.map((data) => {
                return (
                  <div className="icon-box">
                    <img src={data.imgIcon} alt="icon" />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="content-section left-style">
              <div className="index-style">
                <div className="title-lg text-green">Venture Capital</div>
                <p className="description">
                Starting out is a steep climb and costs money. With us, you won’t run out of fuel.
                </p>
                <Link to="/investment" className="viewmore_pro">View more</Link>
              </div>
              <div className="bg2" />
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default BuildProducts;
