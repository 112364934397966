// Images
import image1 from "../../../assets/images/investment-page/bg/w1.svg";
import image2 from "../../../assets/images/investment-page/bg/w2.svg";
import image3 from "../../../assets/images/investment-page/bg/w3.svg";
import image4 from "../../../assets/images/investment-page/bg/w4.svg";

// Styles
import "./styles.scss";

function OurWordSection(props) {
  return (
    <div className={`word-section-style ${props.classContainer}`}>
      <h4 className="title">Don’t just take our word for it!</h4>
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-sm-6">
            <div className="content-box box1">
              <h4 className="title-sm">
                "Their proven expertise in the digital ecosystem transforms into indispensable insights and growth hacks"
              </h4>
              <p className="description">Fitso - Naman Sharma</p>
              <div className="imgBg">
                <img src={image1} alt="Fitso - Naman Sharma"  />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="content-box box2">
              <h4 className="title-sm">
                "While being visionaries who guide us to see the big picture, they don't hesitate to get their hands dirty"
              </h4>
              <p className="description">Tryndbuy - Nitin Vats</p>

              <div className="imgBg">
                <img src={image2} alt="Tryndbuy - Nitin Vats"  />
              </div>
            </div>
          </div>
          <div className="col-sm-6 order1">
            <div className="content-box box3">
              <h4 className="title-sm">
                "AH team's operational knowledge and trend forecasting experience has helped us grow exponentially"
              </h4>
              <p className="description">Krishfy - Rajesh Ranjan</p>
              <div className="imgBg">
                <img src={image3} alt="Krishfy - Rajesh Ranjan"  />
              </div>
            </div>
          </div>
          <div className="col-sm-6 order2">
            <div className="content-box box4">
              <h4 className="title-sm">
                "Unlike some traditional VCs, AH team is truely agile and entrepreneur-first"
              </h4>
              <p className="description">Dukaan - Suumit Shah</p>
              <div className="imgBg">
                <img src={image4} alt="Dukaan - Suumit Shah"  />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurWordSection;
