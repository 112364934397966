import { useEffect } from "react";
import CardSlider from "../../sliders/cardSlider";

import { TimelineMax, Linear, TweenMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import { ScrollMagicPluginIndicator } from "scrollmagic-plugins"

// Images
import slideImage1 from "../../../assets/portfolio/Screenshots_1.png";
import slideImage2 from "../../../assets/portfolio/Screenshots_2.png";
import slideImage3 from "../../../assets/portfolio/Screenshots_3.png";
import slideImage4 from "../../../assets/portfolio/Screenshots_4.png";
import slideImage6 from "../../../assets/portfolio/SmartShoppr.png";
import slideImage7 from "../../../assets/portfolio/Screenshots_7.png";
import slideImage8 from "../../../assets/portfolio/Screenshots_8.png";
import slideImage9 from "../../../assets/portfolio/Screenshots_9.png";
import slideImage10 from "../../../assets/portfolio/Screenshots_10.png";
import ele1 from "../../../assets/portfolio/Screenshot_Elevate_1.png";
import ele2 from "../../../assets/portfolio/Screenshot_Elevate_2.png";
import ele3 from "../../../assets/portfolio/Screenshot_Elevate_3.png";
import ele4 from "../../../assets/portfolio/Screenshot_Elevate_4.png";
import ele5 from "../../../assets/portfolio/Screenshot_Elevate_5.png";
import ele6 from "../../../assets/portfolio/Screenshot_Elevate_6.png";
import eng1 from "../../../assets/portfolio/Screenshot_Engage_1.png";
import eng2 from "../../../assets/portfolio/Screenshot_Engage_2.png";
import eng3 from "../../../assets/portfolio/Screenshot_Engage_3.png";
import eng4 from "../../../assets/portfolio/Screenshot_Engage_4.png";
import eng5 from "../../../assets/portfolio/Screenshot_Engage_5.png";
import eng6 from "../../../assets/portfolio/Screenshot_Engage_6.png";
import triangle from "../../../assets/shapes/triangle.svg";

// Images
import imageIcons from "../../../assets/shapes/icons.png";

// Styles
import "./styles.scss";

function Ecosystem() {
  const media = window.matchMedia(`(min-width: 768px)`);

  useEffect(() => {
    if (media.matches === true) {
      ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);
      ScrollMagicPluginIndicator(ScrollMagic);

      var tl = new TimelineMax({ onUpdate: updatePercentage });
      const controllerScroll1 = new ScrollMagic.Controller();
      tl.from("#iconImageSection", 1, {
        keyframes: [
          {
            opacity: 0.3,
          },
          {
            rotationY: 0,
            rotationX: -40,
            zIndex: 9,
            opacity: 0.3,
            scale: 1.8,
          },
          {
            position: "fixed",
          },
        ],
        ease: Linear.easeInOut,
      });

      const scene = new ScrollMagic.Scene({
        triggerElement: ".second-section",
        triggerHook: "onLeave",
        duration: "100%",
      })
        .setPin(".second-section")
        .setTween(tl)
        .addTo(controllerScroll1);

      function updatePercentage() {
        tl.progress();
      }
    }
  }, []);

  const flickityOptions = {
    pageDots: false,
    prevNextButtons: true,
    // cellAlign: media.matches === true ? 'center' : "left",
    contain: true,
    imagesLoaded: true,
    autoPlay: media.matches === true ? 1500 : null,
    wrapAround: false,
  };

  const cardImages = [
    {
      imgUrl: slideImage9,
      aos: "200",
    },
    {
      imgUrl: slideImage8,
      aos: "300",
    },
    {
      imgUrl: slideImage6,
      aos: "500",
    },
    {
      imgUrl: slideImage1,
      aos: "400",
    },
    {
      imgUrl: slideImage7,
      aos: "600",
    },
    {
      imgUrl: slideImage4,
      aos: "500",
    },

    {
      imgUrl: slideImage3,
      aos: "700",
    },
    // {
    //   imgUrl: slideImage2,
    //   aos: "800",
    // },
    {
      imgUrl: slideImage10,
      aos: "900",
    },
  ];
  const weelewateImages = [
    {
      imgUrl: ele2,
      aos: "200",
    },
    {
      imgUrl: ele5,
      aos: "300",
    },
    {
      imgUrl: ele4,
      aos: "400",
    },
    {
      imgUrl: ele6,
      aos: "500",
    },
    {
      imgUrl: ele3,
      aos: "500",
    },
    {
      imgUrl: ele1,
      aos: "600",
    },
  ];
  const weengageImages = [
    {
      imgUrl: eng3,
      aos: "200",
    },
    {
      imgUrl: eng2,
      aos: "300",
    },
    {
      imgUrl: eng1,
      aos: "400",
    },
    {
      imgUrl: eng4,
      aos: "500",
    },
    {
      imgUrl: eng6,
      aos: "600",
    },
    {
      imgUrl: eng5,
      aos: "700",
    },
  ];

  return (
    <main className="ecosystem-style">
      <section className="first-section">
        <div
          id="ecosystemSection"
          className="ecosystem-section"
          data-aos="fade-right"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
        >
          <h4 className="title4">The Ecosystem</h4>
          <h2 className="title1">
            Decoding the <br /> Digital Hierarchy <br />of Needs
          </h2>
          <p className="description">
            We are building a diverse, inclusive and sustainable ecosystem
            centered around what we call, the Digital Hierarchy of Needs.
          </p>
        </div>
        <div
          className="icon-section"
          data-aos="fade-left"
          data-aos-offset="500"
          data-aos-duration="500"
        >
          <div className="shape-bg">
            <div className="img-section">
              <img src={triangle} className="img-triangle" alt="shape" />
              <img src={imageIcons} className="image" alt="shape" />
            </div>
          </div>
        </div>
      </section>

      {/* ========================== */}

      <section className="second-section">
        <div id="iconImageSection" className="icon-section">
          <div className="shape-bg">
            <div className="img-section">
              <img src={triangle} className="img-triangle" alt="shape" />
            </div>
          </div>
        </div>
      </section>

      {/* ========================== */}

      <div className="we-enable-section" id="slideContainer">
        <section id="cardSlide01" className="panel panel1">
          <div className="content-section">
            <h1 className="title1">We Enable</h1>
            <p className="description">
              We start with the basics, building an array of transactional <br />
              digital services aiming at convenience and utility.
            </p>
          </div>

          <CardSlider className="custom-carousel" options={flickityOptions}>
            {cardImages.map((data, index) => {
              return (
                <div
                  className="slider-item"
                  key={index}
                  data-aos-once="true"
                  data-aos="fade-left"
                  data-aos-delay={`${data.aos}`}
                >
                  <img src={data.imgUrl} alt="icon" />
                </div>
              );
            })}
          </CardSlider>
        </section>

        <section id="cardSlide02" className="panel panel2">
          <div className="content-section">
            <h1 className="title1">We Engage</h1>
            <p className="description">
              Because in this disconnected and noisy digital world, people crave <br />
              for personalized content and genuine connections.
            </p>
          </div>
          <CardSlider className="custom-carousel" options={flickityOptions}>
            {weengageImages.map((data, index) => {
              return (
                <div
                  className="slider-item"
                  key={index}
                  data-aos-once="true"
                  data-aos="fade-left"
                  data-aos-delay={`${data.aos}`}
                >
                  <img src={data.imgUrl} alt="icon"/>
                </div>
              );
            })}
          </CardSlider>
        </section>

        <section id="cardSlide03" className="panel panel3">
          <div className="content-section">
            <h1 className="title1">We Elevate</h1>
            <p className="description">
              For this digital native generation seeking a higher sense of <br />
              purpose, we catapult growth of those who dare to go beyond.
            </p>
          </div>

          <CardSlider className="custom-carousel" options={flickityOptions}>
            {weelewateImages.map((data, index) => {
              return (
                <div
                  className="slider-item"
                  key={index}
                  data-aos-once="true"
                  data-aos="fade-left"
                  data-aos-delay={`${data.aos}`}
                >
                  <img src={data.imgUrl} alt="icon" />
                </div>
              );
            })}
          </CardSlider>
        </section>
      </div>
    </main>
  );
}

export default Ecosystem;
