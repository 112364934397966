import "./styles.scss";

import playstore from "../../../assets/icons/playstore.png";
import linkedin from "../../../assets/icons/social/linkedin.svg";
import instagram from "../../../assets/icons/social/instagram.svg";
import medium from "../../../assets/icons/social/medium.png";

function MediaDetails(props) {
  return (
    <div className={`fuelling-imagination-style ${props.classContainer}`}>
      <div className="inner-section">
        <div className="container">
          <div>
            <p className="title">Who we are?</p>
            <p>
            An internet tech company the builds 
            global products to engage & inspiring millions
            </p>
            <br />
            <p className="subtitle">Logo</p>
            <table id="customers">
              <tr>
                <td>Download</td>
                <td>
                  {" "}
                  <a href="/AppyHigh(PNG).png" download>
                    PNG
                  </a>
                </td>
                <td>
                  <a href="/AppyHigh(JPG).jpg" download>
                    JPG
                  </a>
                </td>
                <td>
                  <a href="/AppyHigh(EPS).eps" download>
                    EPS
                  </a>
                </td>
              </tr>
            </table>
            <br />
            <p className="subtitle">Company Details</p>
            <table id="customers">
              <tr>
                <td>Year of Launch</td>
                <td>2018</td>
              </tr>
              <tr>
                <td>Founders</td>
                <td>Venus Dhuria, Aneesh Rayancha</td>
              </tr>
              <tr>
                <td>HQ Address</td>
                <td>201, SS Plaza, Sector 47, Gurgaon Haryana, 122018</td>
              </tr>
              <tr>
                <td>Offices In</td>
                <td>Gurgaon, Hyderabad</td>
              </tr>
              <tr>
                <td>Company Size</td>
                <td>70+</td>
              </tr>
              <tr>
                <td>Fundraising Status</td>
                <td>Bootstrapped</td>
              </tr>
              <tr>
                <td>Company Overview</td>
                <td>
                  AppyHigh is a mobile internet technology company that builds
                  everyday apps for the masses. We have launched 15+ chartbuster
                  applications that have garnered 400mn+* downloads, and are
                  perpetually hustling to simplify and enrich the digital world.
                  <br />
                  <br />
                  We are also a venture capital firm that backs early-stage
                  start-ups with capital, resources and heart.
                  <br />
                  <br />
                  Founded by third-generation tech entrepreneurs who have made
                  successful exits in the past and backed by an enthusiastic
                  team, AppyHigh is creating technology for a simpler, better
                  tomorrow. <br />
                  <br />
                  <i>*data as per Jul’21</i>
                </td>
              </tr>

              <tr>
                <td>Vision</td>
                <td>
                We envision a world where living is simple, decluttered and 
                enriching. We are creating that reality, one product at a time!
                </td>
              </tr>

              <tr>
                <td>Purpose</td>
                <td>
                We're mitigating friction in everyday lives, fostering accessibility to 
                cutting edge technology & enabling enhanced productivity to save precious time.
                </td>
              </tr>

              <tr>
                <td>Social Media Accounts</td>
                <td>
                  <div className="social-links">
                    <a
                      href="https://www.linkedin.com/company/appyhigh/"
                      rel="noreferrer"
                      target="_blank"
                      className="link-item"
                    >
                      <img src={linkedin} alt="icon" />
                    </a>
                    <a
                      href="https://www.instagram.com/iamappyhigh/"
                      rel="noreferrer"
                      target="_blank"
                      className="link-item"
                    >
                      <img src={instagram} alt="icon" />
                    </a>
                    <a
                      href="https://medium.com/appyhigh-technology-blog"
                      rel="noreferrer"
                      target="_blank"
                      className="link-item"
                    >
                      <img src={medium} alt="icon" />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/dev?id=8696216234951434473"
                      rel="noreferrer"
                      target="_blank"
                      className="link-item"
                    >
                      <img src={playstore} alt="icon" />
                    </a>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MediaDetails;
