import "./styles.scss";
function IndependentSection(props) {
  return (
    <div className={`independent-section-style ${props.classContainer}`}>
      <div className="container position-relative">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h2 className="title">
              The cornerstone of our philosophy is to thrive as proudly contrarian, bold yet patient investors
            </h2>
          </div>
        </div>
      </div>
      <div className="bg1" />
      <div className="bg2" />
    </div>
  );
}

export default IndependentSection;
