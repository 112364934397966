import React, { useEffect } from "react";
import HelmetCustom from "../../components/helmetCustom";

// sections
import HeroSec from "../../components/aboutus/hero";
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import VisionSec from "../../components/aboutus/vision";
import Combinedsec from "../../components/aboutus/combined";
import Storyboard from "../../components/aboutus/storyboard";
import Team from "../../components/aboutus/Team";
import JoinUs from "../../components/joinUs";
import Anatomy from "../../components/aboutus/anatomy";
import AppannieSection from "../../components/aboutus/appannieSection";

// Images
import venus from "../../assets/images/aboutus/venus.png";
import aneesh from "../../assets/images/aboutus/team/2_AneeshRayancha.png";
import akshit from "../../assets/images/aboutus/team/3_AkshitRaja.png";
import ankit_khe from "../../assets/images/aboutus/team/4_AnkitKhemka.png";
import ishanrelan from "../../assets/images/aboutus/team/6_IshanRelan.png";
import jashansharma from "../../assets/images/aboutus/team/7_JashanSharma.png";
import praneethraj from "../../assets/images/aboutus/team/8_PraneethRajulapudi.png";
import abhishekkumar from "../../assets/images/aboutus/team/11_AbhishekKumar.png";
import brajeshkumar from "../../assets/images/aboutus/team/14_BrajeshKumar.png";
import harmanpreetsin from "../../assets/images/aboutus/team/16_HarmanpreetSingSran.png";
import kartikman from "../../assets/images/aboutus/team/17_KartikManchanda.png";
import kunalchamoli from "../../assets/images/aboutus/team/18_KunalChamoli.png";
import mandeepsharma from "../../assets/images/aboutus/team/19_MandeepSharma.png";
import mayankksahu from "../../assets/images/aboutus/team/20_MayankKSahu.png";
import nehaverma from "../../assets/images/aboutus/team/22_NehaVerma.png";
import neharikasado from "../../assets/images/aboutus/team/23_NeharikaSadotra.png";
import pushkar from "../../assets/images/aboutus/team/25_Pushkar.png";
import kishorereddy from "../../assets/images/aboutus/team/26_S.Kishorereddy.png";
import saicharan from "../../assets/images/aboutus/team/27_Saicharanreddykantigari.png";
import samitasharma from "../../assets/images/aboutus/team/28_SamitaSharma.png";
import sarathkumar from "../../assets/images/aboutus/team/29_SarathKumar.png";
import shivanisaini from "../../assets/images/aboutus/team/31_ShivaniSaini.png";
import sravankumar from "../../assets/images/aboutus/team/33_SravanKumar.png";
import sumitsharma from "../../assets/images/aboutus/team/34_SumitSharma.png";
import sunnyraj from "../../assets/images/aboutus/team/35_SunnyRaj.png";
import tejasommanchi from "../../assets/images/aboutus/team/36_TejaSomanchi.png";
import utkarshbaranwal from "../../assets/images/aboutus/team/38_UtkarshBaranwal.png";
import vinaykumar from "../../assets/images/aboutus/team/39_VinayKumar.png";
import aishikkirtaniya from "../../assets/images/aboutus/team/41_AishikKirtaniya.png";
import aryanverma from "../../assets/images/aboutus/team/42_AryanVerma.png";
import asishkhan from "../../assets/images/aboutus/team/43_AsishKhan.png";
import avirajsingh from "../../assets/images/aboutus/team/44_AvirajSingh.png";
import manavaggarwal from "../../assets/images/aboutus/team/46_ManavAggarwal.png";
import pradyumnjain from "../../assets/images/aboutus/team/47_PradyumnJain.png";
import satvikchachra from "../../assets/images/aboutus/team/48_SatvikChachra.png";
import sujanpoojary from "../../assets/images/aboutus/team/49_SujanPoojary.png";
import saurabh from "../../assets/images/aboutus/team/Suarabh.png";

// Styles
import "./aboutus.scss";
import { useLocation } from "react-router-dom";

const story = [
  {
    date: "Jun 2012",
    text: "This story of a match made in digital heaven, started with Venus and Aneesh's serendipitous encounter in Reckitt back in 2012.",
    milestone: "",
  },
  {
    date: "Apr 2014",
    text: "After two years of courtship, they decided to take their friendship to the next level when they co-created their first baby, Rutogo, an out-station cabs aggregator and moved into 20 different cities.",
    milestone: "5M+ Downloads",
  },
  {
    date: "Jan 2015",
    text: "After an adventurous first year, they committed to this realtionship for the long haul and upgraded their venture to an All-in-one App for intracity and intercity cabs.",
    milestone: "Crossed 1000 Bookings per month",
  },
  {
    date: "Aug 2015",
    text: "As this successful partnership caught eyeballs, Ixigo bought the IP rights and rebranded Rutogo to Ixigo Cabs, which they co-led for 1.5 years.",
    milestone: "Acquired by Ixigo",
  },
  {
    date: "Dec 2016",
    text: "Post this awesome threesome stint, they kept going strong and launched AnyJob & Samaachari, jobs & news aggregator platforms.",
    milestone: "Acquired 1M+ downloads",
  },
  {
    date: "Mar 2017",
    text: "They hit the next milestone in their story with SmartShoppr, an all-in-one shopping and deals app that garnered 5Mn+ downloads.",
    milestone: "Avg 60k+ orders placed every month",
  },
  {
    date: "Jul 2017",
    text: "Unlike most long-term relationships, theirs did not lose it's spark as they brought yet another project to life- Messenger Go, an all-in-one social media App and also conducted Hackathon where 15+ Devs worked on 15 prototypes.",
    milestone: "10+ Apps launched within a week",
  },
  {
    date: "Dec 2017",
    text: "Their companionship and passion caught on with the US audience as people connected with their offering of easy accessibility and mobility.",
    milestone: "10 mins+ average session time in MGo",
  },
  {
    date: "Jun 2018",
    text: "As they approached the infamous 7 year itch in a relationship, their faith was reaffirmed by over 25 Mn users who shared their love for productivity and social media.",
    milestone: "Crossed 25Mn+ Downloads",
  },
  {
    date: "Oct 2018",
    text: "With a backdrop of successful digital stints, AppyHigh was formally envisioned as a mobile internet technology company that builds global products to engage and inspire millions, everyday.",
    milestone: "AppyHigh is officially registered",
  },
  {
    date: "Mar 2019",
    text: "A new vertical of apps aimed at creating an engaged and high-quality user pool was built. This comprised of content and live streaming apps like Pally, TV Lens and more.",
    milestone: "Content & Live Streaming verticals launched ",
  },
  {
    date: "Aug 2019",
    text: "An ensemble of niche platforms launched to empower the creators ecosystem and enhance business efficiences for enterprises.",
    milestone: "Creators and B2B verticals launched",
  },
  {
    date: "Jun 2020",
    text: "A major strategic alignment to shift focus to the specific needs of the Indian market and then replicating the success formula to scale globally.",
    milestone: "Bharat Apps was conceptualised",
  },
  {
    date: "Jun 2022",
    text: "Piloted creativity & photo editing tools powered by Generative AI",
    milestone: "Generative AI products begin testing",
  },
  {
    date: "Jun 2023",
    text: "Launched a suite of creativity and photo editing apps powered by Generative AI -ImagineGO, PixelGO & AI Avatar Maker. Launched Phot.AI - an AI powered comprehensive web based visual design platform that aims to simplify the creative & photo/video editing workflows and save time & money.",
    milestone: "Generative AI segment launched",
  },
];

const Teammember = [
  {
    client_id: 1,
    id: 1,
    image: venus,
    name: "Venus Dhuria",
    designation: "Co-Founder",
  },
  {
    client_id: 1,
    image: aneesh,
    name: "Aneesh Rayancha",
    designation: "Co-Founder",
  },
  {
    client_id: 1,
    image: akshit,
    name: "Akshit Raja",
    designation: "Head of Data Science & AI",
  },
  {
    client_id: 1,
    image: ankit_khe,
    name: "Ankit Khemka",
    designation: "Head of Finance",
  },
  {
    client_id: 1,
    image: saurabh,
    name: "Saurabh Malhotra",
    designation: "Head of Operations Strategy & Marketing",
  },
  {
    client_id: 1,
    image: ishanrelan,
    name: "Ishan Relan",
    designation: "VP - Business Growth",
  },
  {
    client_id: 1,
    image: jashansharma,
    name: "Jashan Sharma",
    designation: "Entrepreneur in Residence",
  },
  {
    client_id: 1,
    image: praneethraj,
    name: "Praneeth Rajulapudi",
    designation: "Technical Team Lead",
  },
  {
    client_id: 1,
    image: abhishekkumar,
    name: "Abhishek Kumar",
    designation: "Backend Developer",
  },
  {
    client_id: 1,
    image: brajeshkumar,
    name: "Brajesh Kumar",
    designation: "Research Science Artificial Intelligence",
  },
  {
    client_id: 1,
    image: harmanpreetsin,
    name: "Harmanpreet Sing Sran",
    designation: "Project Manager",
  },
  {
    client_id: 1,
    image: kartikman,
    name: "Kartik Manchanda",
    designation: "Manager- Growth",
  },
  {
    client_id: 1,
    image: kunalchamoli,
    name: "Kunal Chamoli",
    designation: "Data Scientist",
  },
  {
    client_id: 1,
    image: mandeepsharma,
    name: "Mandeep Sharma",
    designation: "Creative Strategist",
  },
  {
    client_id: 1,
    image: mayankksahu,
    name: "Mayank K Sahu",
    designation: "Sr. UI UX Designer",
  },
  {
    client_id: 1,
    image: nehaverma,
    name: "Neha Verma",
    designation: "Talent Acquisition Specialist - Technology",
  },
  {
    client_id: 1,
    image: neharikasado,
    name: "Nishtha Saharan",
    designation: "Customer Success Manager",
  },
  {
    client_id: 1,
    image: kishorereddy,
    name: "S. Kishore Reddy",
    designation: "SDE-I",
  },
  {
    client_id: 1,
    image: pushkar,
    name: "Pushkar",
    designation: "Software Developer ( Backend)",
  },
  {
    client_id: 1,
    image: saicharan,
    name: "Sai Charan Reddy Kantigari",
    designation: "SDE-I",
  },
  {
    client_id: 1,
    image: samitasharma,
    name: "Samita Sharma",
    designation: "Assistant Manager-HR Ops",
  },
  {
    client_id: 1,
    image: sarathkumar,
    name: "Sarath Kumar",
    designation: "Backend developer",
  },
  {
    client_id: 1,
    image: shivanisaini,
    name: "Shivani Saini",
    designation: "Jr. UI UX Designer",
  },
  {
    client_id: 1,
    image: sravankumar,
    name: "Sravan Kumar",
    designation: "Quality Assurance Tester",
  },
  {
    client_id: 1,
    image: sumitsharma,
    name: "Sumit Sharma",
    designation: "Product Designer",
  },
  {
    client_id: 1,
    image: sunnyraj,
    name: "Sunny Raj",
    designation: "ML Engineer",
  },
  {
    client_id: 1,
    image: utkarshbaranwal,
    name: "Utkarsh Baranwal",
    designation: "SDE-I (mobile)",
  },
  {
    client_id: 1,
    image: vinaykumar,
    name: "Vinay Kumar",
    designation: "Unity Developer",
  },
  {
    client_id: 1,
    image: aishikkirtaniya,
    name: "Aishik Kirtaniya",
    designation: "Android developer-Intern",
  },
  {
    client_id: 1,
    image: aryanverma,
    name: "Aryan Verma",
    designation: "Android AI Intern",
  },
  {
    client_id: 1,
    image: asishkhan,
    name: "Asish Khan",
    designation: "UI Design Intern",
  },
  {
    client_id: 1,
    image: avirajsingh,
    name: "Aviraj Singh",
    designation: "Android development Intern",
  },
  {
    client_id: 1,
    image: manavaggarwal,
    name: "Manav Aggarwal",
    designation: "Android developer-Intern",
  },
  {
    client_id: 1,
    image: pradyumnjain,
    name: "Pradyumn Jain",
    designation: "Machine Learning Intern",
  },
  {
    client_id: 1,
    image: satvikchachra,
    name: "Satvik Chachra",
    designation: "Full Stack Intern",
  },
  {
    client_id: 1,
    image: sujanpoojary,
    name: "Sujan Poojary",
    designation: "Backend Developer-Intern",
  },
];

const clients = [
  { name: "aaa", id: 1 },
  { name: "bbb", id: 2 },
];

const Aboutus = () => {
  const location = useLocation();

  /**
   * Added to fix anchor # paths not working in chrome
   */
  useEffect(() => {
    setTimeout(() => {
      if (location.hash && location.hash !== "#") {
        const anchorLocation = decodeURIComponent(location.hash);
        const anchorElement = document.querySelector(anchorLocation);
        if (anchorElement && anchorElement.offsetTop)
          anchorElement.scrollIntoView();
      }
    }, 100);
  }, [location]);

  return (
    <>
      <HelmetCustom
        title="About Us"
        metaDescription="Imagine a world where living is simple, decluttered and enriching. We are creating that reality, one app at a time!"
      />
      <div className="about-root">
        <Navbar />

        <div id="hero-sec">
          <HeroSec />
        </div>
        <div id="visionsec">
          <VisionSec />
        </div>
        <div id="Anatomy">
          <Anatomy />
        </div>
        <div id="Storyboard">
          <Storyboard items={story} />
        </div>
        <div className="AppAnnie">
          <AppannieSection />
        </div>
        <div className="teamAbout" id="teamAbout">
          <Team teams={Teammember} client={clients} />
        </div>
        <div className="joinus">
          <JoinUs href="/careers" btnName={"Join Us"}>
            <h4 className="title">
              Join a rock-solid team of smart go-getters
            </h4>
          </JoinUs>
        </div>
        <section>
          <Footer />
        </section>
      </div>
    </>
  );
};

export default Aboutus;
