// import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-scroll";
// Images
import logo from "../../assets/images/logo/appyhighF.svg";
import logoIcon from "../../assets/images/logo/logo-icon-dark.svg";

import playstore from "../../assets/icons/playstore.png";
import linkedin from "../../assets/icons/social/linkedin.svg";
import instagram from "../../assets/icons/social/instagram.svg";
import medium from "../../assets/icons/social/medium.png";

// Styles
import "./styles.scss";

function Footer(props) {
  const year = new Date().getFullYear();
  return (
    <div className={`footer-style ${props.classContainer}`}>
      <div className="mobile-footer">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="links-column">
                <HashLink className="link-item" to="/aboutus">
                  About Us
                </HashLink>
                <HashLink className="link-item" to="/product">
                  Products
                </HashLink>
                <HashLink className="link-item" to="/investment">
                  Investment
                </HashLink>
                <HashLink className="link-item" to="/careers">
                  Careers
                </HashLink>
              </div>
            </div>
            <div className="logo-mobile">
              <a href="/">
                {" "}
                <img src={logoIcon} alt="logo" />
              </a>
            </div>
            <div className="col-sm-12">
              <div className="about-company">
                An internet tech company the builds global products to engage &
                inspiring millions
              </div>
            </div>
            <div className="col-sm-12">
              <div className="social-links">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="link-item playstore"
                  href="https://play.google.com/store/apps/dev?id=8696216234951434473"
                >
                  <img src={playstore} alt="icon" />
                </a>
                <a
                  className="link-item"
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.linkedin.com/company/appyhigh/"
                >
                  <img src={linkedin} alt="icon" />
                </a>
                <a
                  className="link-item"
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.instagram.com/iamappyhigh/"
                >
                  <img src={instagram} alt="icon" />
                </a>
                <a
                  className="link-item"
                  rel="noreferrer"
                  target="_blank"
                  href="https://medium.com/appyhigh-technology-blog"
                >
                  <img src={medium} alt="icon" />
                </a>
              </div>

              <div className="copyright-mob">
                © {year}. All rights reserved. AppyHigh
              </div>
              <div className="copyright-mob">
                <a href="/privacy-policy" rel="noreferrer" target="_blank">
                  Privacy Policy
                </a>
              </div>
              <div className="copyright-mob">
                <a href="/media" rel="noreferrer" target="_blank">
                  Media Kit
                </a>
              </div>
              {/* <div className="other-links mt--15">
                <HashLink className="link-item">Privacy Policy</HashLink>
                <HashLink className="link-item">Terms & Conditions</HashLink>
                <HashLink className="link-item">Site map</HashLink>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="desktop-footer">
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <a href="/">
                {" "}
                <img src={logo} className="logoicon" alt="logo" />
              </a>
              <div className="about-text">
                An internet tech company the builds global products to engage &
                inspiring millions.
              </div>
            </div>
            <div className="col-sm-2">
              <div className="title">
                <HashLink className="link-item item-head" to="/aboutus">
                  About
                </HashLink>
              </div>
              <div className="links">
                <HashLink className="link-item" to="/aboutus#visionsec">
                  Values
                </HashLink>
                <HashLink className="link-item" to="/aboutus#Anatomy">
                  Logo
                </HashLink>
                <HashLink className="link-item" to="/aboutus#Combinedsec">
                  Ecosystem
                </HashLink>
                <HashLink className="link-item" to="/aboutus#Storyboard">
                  Journey
                </HashLink>
                <HashLink className="link-item" to="/aboutus#teamAbout">
                  Team
                </HashLink>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="title">
                <HashLink className="link-item item-head" to="/product">
                  Product
                </HashLink>
              </div>
              <div className="links">
                <HashLink className="link-item" to="/product#Whowe">
                  Strategy
                </HashLink>
                <HashLink className="link-item" to="/product#SuperApps">
                  SuperApps
                </HashLink>
                <HashLink className="link-item" to="/product#Utility">
                  Utility
                </HashLink>
                <HashLink className="link-item" to="/product#Content">
                  Content
                </HashLink>
                <HashLink className="link-item" to="/product#Creators">
                  Creators
                </HashLink>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="title">
                <HashLink className="link-item item-head" to="/investment">
                  Investment
                </HashLink>
              </div>
              <div className="links">
                <HashLink className="link-item" to="/investment#thesis">
                  Thesis
                </HashLink>
                <HashLink className="link-item" to="/investment#portfolio">
                  Portfolio
                </HashLink>
                <HashLink className="link-item" to="/investment#philosophy">
                  Philosophy
                </HashLink>
                <HashLink className="link-item" to="/investment#process">
                  Process
                </HashLink>
                <HashLink className="link-item" to="/investment#partners">
                  Partners
                </HashLink>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="title">
                <HashLink className="link-item item-head" to="/careers">
                  Careers
                </HashLink>
              </div>

              <div className="links">
                <HashLink className="link-item" to="/careers#culture">
                  Culture
                </HashLink>
                <HashLink className="link-item" to="/careers#openingsParent">
                  Openings
                </HashLink>
                <HashLink className="link-item" to="/careers#pillars">
                  Pillars
                </HashLink>
                <HashLink className="link-item" to="/developer-form">
                  For Developers
                </HashLink>
              </div>
            </div>
          </div>

          <div className="row mt--65">
            <div className="col-sm-6">
              <div className="copyright">
                ©{year}. all rights reserved. Appyhigh
              </div>
              <div className="two-things">
                <a href="/privacy-policy" rel="noreferrer" target="_blank">
                  Privacy Policy
                </a>
                &nbsp;&nbsp;
                <a href="/media" rel="noreferrer" target="_blank">
                  Media Kit
                </a>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="social-links">
                <a
                  href="https://www.linkedin.com/company/appyhigh/"
                  rel="noreferrer"
                  target="_blank"
                  className="link-item"
                >
                  <img src={linkedin} alt="icon" />
                </a>
                <a
                  href="https://www.instagram.com/iamappyhigh/"
                  rel="noreferrer"
                  target="_blank"
                  className="link-item"
                >
                  <img src={instagram} alt="icon" />
                </a>
                <a
                  href="https://medium.com/appyhigh-technology-blog"
                  rel="noreferrer"
                  target="_blank"
                  className="link-item"
                >
                  <img src={medium} alt="icon" />
                </a>
                <a
                  href="https://play.google.com/store/apps/dev?id=8696216234951434473"
                  rel="noreferrer"
                  target="_blank"
                  className="link-item"
                >
                  <img src={playstore} alt="icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
