import "./styles.scss";
import folderTop from "../../../assets/shapes/folder-top.svg";
import folderBottom from "../../../assets/shapes/folder-bottom.svg";

function AllProductLogos(props) {
  return (
    <div className={`fuelling-imagination-style ${props.classContainer}`}>
      <div className="inner-section">
        <div className="container">
          <div>
            <p className="title">All Product Logos</p>
            <br />
            <table id="customers">
              <tr>
                <td><span className="folder-icon"><img src={folderTop} className="img-folder" alt="shape" /><img src={folderBottom} alt="shape" /></span>Product Logos</td>
                <td>
                  <a href="/app-logos.zip" download>
                    Format types: JPEG, PNG, SVG
                  </a>
                </td>
              </tr>
            </table>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllProductLogos;
