// import {Switch, Route, BrowserRouter} from "react-router-dom";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import Careers from "./containers/careers";
import Home from "./containers/home";
import Investment from "./containers/investment";
import Aboutus from "./containers/aboutus";
import Product from "./containers/product";
import JobDetail from "./containers/jobdetail";
import NotFound from "./containers/notFound";
import Contact from "./containers/contact";
import ScrollToTop from "./components/scrollToTop";
import PrivacyPolicy from "./containers/privacyPolicy";
import Form from "./containers/form";
import PressAndMedia from "./containers/pressandmedia";
import GoogleAnalytics from "./GoogleAnalytics";

function AppRouter() {
  return (
    <div style={{}}>
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/investment" component={Investment} />
          <Route exact path="/careers" component={Careers} />
          <Route exact path="/aboutus" component={Aboutus} />
          <Route exact path="/product" component={Product} />
          <Route exact path="/notfound" component={NotFound} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/media" component={PressAndMedia} />
          <Route exact path="/developer-form" component={Form} />
          <Route exact path="/affiliate-form" component={Form} />
          <Route
            path="/careers/:name"
            render={(props) => <JobDetail {...props} />}
          />
        </Switch>
        <GoogleAnalytics />
      </BrowserRouter>
    </div>
  );
}

export default AppRouter;
