import { Link } from "react-router-dom";
import React from "react";

import HeroSec from "../../components/privacypolicy/hero";
import PolicyDetails from "../../components/privacypolicy/PolicyDetails";
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
// Styles
import "./styles.scss";

const PrivacyPolicy = () => {
  return (
    <div>
      <div className="">
        <Navbar />
      </div>
      <div className="">
        <HeroSec />
      </div>
      <div className="">
        <PolicyDetails />
      </div>

      <div className="">
        <Footer />
      </div>

      {/* <div className="text-section">
        <div className="text-404">
          Privacy Policy for AppyHigh Technology LLP
        </div>
        <div className="text-description">
          <p>
            At appyhigh.com, accessible from www.appyhigh.com, one of our main
            priorities is the privacy of our visitors. This Privacy Policy
            document contains types of information that is collected and
            recorded by appyhigh.com and how we use it. If you have additional
            questions or require more information about our Privacy Policy, do
            not hesitate to contact us. This Privacy Policy applies only to our
            online activities and is valid for visitors to our website with
            regards to the information that they shared and/or collect in
            appyhigh.com. This policy is not applicable to any information
            collected offline or via channels other than this website. Our
            Privacy Policy was created with the help of the Privacy Policy
            Generator.
          </p>
          <h3>Consent</h3>
          <p>
            By using our website, you hereby consent to our Privacy Policy and
            agree to its terms.
          </p>
          <h3>Information we collect</h3>
          <p>
            The personal information that you are asked to provide, and the
            reasons why you are asked to provide it, will be made clear to you
            at the point we ask you to provide your personal information. If you
            contact us directly, we may receive additional information about you
            such as your name, email address, phone number, location, the
            contents of the message and/or attachments you may send us, and any
            other information you may choose to provide. When you register for
            an Account, we may ask for your contact information, including items
            such as name, company name, address, email address, and telephone
            number.
          </p>
          <h3>How we use your information</h3>
          <p>
            We use the information we collect in various ways, including to:
            Provide, operate, and maintain our website Improve, personalize, and
            expand our website Understand and analyze how you use our website
            Develop new products, services, features, and functionality
            Communicate with you, either directly or through one of our
            partners, including for customer service, to provide you with
            updates and other information relating to the website, and for
            marketing and promotional purposes Send you emails Find and prevent
            fraud
          </p>
          <h3>Log Files</h3>
          <p>
            appyhigh.com follows a standard procedure of using log files. These
            files log visitors when they visit websites. All hosting companies
            do this as part of hosting services' analytics. The information
            collected by log files include internet protocol (IP) addresses,
            browser type, Internet Service Provider (ISP), date and time stamp,
            referring/exit pages, and possibly the number of clicks. These are
            not linked to any information that is personally identifiable. The
            purpose of the information is for analyzing trends, administering
            the site, tracking users' movement on the website, and gathering
            demographic information.
          </p>
          <h3>Cookies and Web Beacons</h3>
          <p>
            Like any other website, appyhigh.com uses 'cookies'. These cookies
            are used to store information including visitors' preferences, and
            the pages on the website that the visitor accessed or visited. The
            information is used to optimize the users' experience by customizing
            our web page content based on visitors' browser type and/or other
            information. For more general information on cookies, please read
            "What Are Cookies".
          </p>
          <h3>Advertising Partners Privacy Policies</h3>
          <p>
            You may consult this list to find the Privacy Policy for each of the
            advertising partners of appyhigh.com. Third-party ad servers or ad
            networks use technologies like cookies, JavaScript, or Web Beacons
            that are used in their respective advertisements and links that
            appear on appyhigh.com, which are sent directly to users' browsers.
            They automatically receive your IP address when this occurs. These
            technologies are used to measure the effectiveness of their
            advertising campaigns and/or to personalize the advertising content
            that you see on websites that you visit. Note that appyhigh.com has
            no access to or control over these cookies that are used by
            third-party advertisers.
          </p>
          <h3>Third Party Privacy Policies</h3>
          <p>
            appyhigh.com's Privacy Policy does not apply to other advertisers or
            websites. Thus, we are advising you to consult the respective
            Privacy Policies of these third-party ad servers for more detailed
            information. It may include their practices and instructions about
            how to opt-out of certain options. You can choose to disable cookies
            through your individual browser options. To know more detailed
            information about cookie management with specific web browsers, it
            can be found at the browsers' respective websites.
          </p>

          <h3>Data Location</h3>
          <p>
            We may in the course of providing Services to you and/or in using
            services from third parties as mentioned elsewhere, transfer, store
            or process the information in different locations, including
            locations outside the territory of India. By using our services, you
            consent to transfer your information to such different or foreign
            locations.
          </p>

          <h3>Changes to this Policy</h3>
          <p>
            This Privacy Policy may be updated from time to time for any reason.
            It is the responsibility of all users of the Services to visit this
            page to check for updates. By visiting, accessing and/or using any
            of the Services, you consent to the terms of this Privacy Policy.
          </p>

          <h3>Contacting Us</h3>
          <p>
            You may write to us at finance@appyhigh.com for
            queries/clarifications regarding our Privacy Policy.
          </p>
        </div>
        <a className="back-btn-style" href="/">
          Back to Home
        </a>
      </div> */}
    </div>
  );
};

export default PrivacyPolicy;
