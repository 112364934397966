import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const PRODUCTION_DEPLOYMENT_URL = "https://appyhigh.com";

function HelmetCustom(props) {
  const location = useLocation();
  const pagePath = location.pathname;

  return (
    <div className="application">
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.metaDescription} />
        <meta
          property="og:url"
          content={`${PRODUCTION_DEPLOYMENT_URL}${pagePath}`}
        />
      </Helmet>
    </div>
  );
}

export default HelmetCustom;
