import Lottie from "react-lottie";
import shape1 from "../../../assets/images/json/logo-formation.json";

// Images
import apps from "../../../assets/images/aboutus/apps.png";
import colon from "../../../assets/images/aboutus/colon.png";
import semicolon from "../../../assets/images/aboutus/semicolon.png";
import appyhigh from "../../../assets/images/aboutus/appyhigh.png";
import plus from "../../../assets/images/aboutus/plus.png";
import arrow from "../../../assets/images/aboutus/arrow.png";

// styles
import "./anatomy.scss";

const AnatomySec = () => {
  // const [isAutoPlay, setIsAutoPlay] = useState(false);
  // const speed = isAutoPlay ? 1 : 4;

  return (
    <div className="anatomysec">
      <div className="era_sec item-box">
        <div className="era_text">
          Bridging the gap between people & technology
        </div>
        <div className="era_subtext">
        We are a multi-product, consumer tech company that is building creativity & 
        productivity platforms to unlock potential & enhance everyday experiences
        </div>
      </div>

      <div className="video_box">
        <div className="lottie-animation">
          <Lottie
            speed={2}
            options={{
              animationData: shape1,
              loop: true,
              autoplay: true,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
          />
        </div>
      </div>
      <div className="image_presentation">
        <div>
          <div className="imagebox">
            <img src={apps} alt="Apps" className="apps" />
          </div>
          <div className="contentbox">
            <div className="title">Apps</div>
            <div className="cont">The quintessential symbol for Apps</div>
          </div>
        </div>
        <img src={plus} alt="" className="centerimg plus_img" />
        <div>
          <div className="imagebox">
            <img src={colon} alt="Colon" className="colon" />
          </div>
          <div className="contentbox">
            <div className="title">Colon</div>
            <div className="cont">Bringing People & Technology together</div>
          </div>
        </div>
        <img src={plus} alt="" className="centerimg plus_img" />
        <div>
          <div className="imagebox">
            <img src={semicolon} alt="Semi Colon" className="semicolon" />
          </div>
          <div className="contentbox">
            <div className="title">Semi Colon</div>
            <div className="cont">Fostering startups with capital & growth</div>
          </div>
        </div>
        <img src={arrow} alt="" className="centerimg arrowimg" />
        <div>
          <div className="imagebox">
            <img src={appyhigh} alt="AppyHigh" className="appyhigh" />
          </div>
          <div className="contentbox">
            <div className="title">AppyHigh</div>
            <div className="cont">
              A coherent ecosystem that thrives on technology, startups and
              people
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnatomySec;
