import "./styles.scss";

function PressRelease(props) {
  return (
    <div className={`fuelling-imagination-style ${props.classContainer}`}>
      <div className="inner-section">
        <div className="container">
          <div>
            <p className="title">Press Release</p>

            <p>
            An internet tech company the builds global products 
            to engage & inspiring millions
            </p>
            <br />
            <p className="subtitle">Company Details</p>
            <table id="customers">
              <tr>
                <td>Feb 06, 2020</td>
                <td>
                  <a href="https://economictimes.indiatimes.com/tech/technology/times-group-reliance-airtel-are-indias-top-app-publishers-app-annie/articleshow/80711578.cms">
                    Appyhigh, top app publishers: App Annie
                  </a>
                </td>
              </tr>
              <tr>
                <td>Aug 03, 2021</td>
                <td>
                  <a href="https://www.livemint.com/companies/start-ups/agritech-startup-krishify-raises-2-7-million-in-pre-series-a-round-11627980716269.html">
                    Agritech Investment, krishify raises $2.7 million in
                    pre-Series A round
                  </a>
                </td>
              </tr>
            </table>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PressRelease;
