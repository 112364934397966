import { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";

// Images
import logoIcon from "../../assets/images/logo/LogoWhite.svg";
import logoIconColor from "../../assets/images/logo/logo-icon-dark.svg";

// Styles
import "./styles.scss";

function Navbar(props) {
  const media = window.matchMedia(`(min-width: 768px)`);

  const [isOpened, setIsOpened] = useState(false);

  function toggle() {
    setIsOpened((isOpened) => !isOpened);
  }

  // let history = useHistory();

  // function handleClick() {
  //   history.push("/");
  // }

  return (
    <div
      className={`navbar-style ${props.className} ${media.matches ? "" : "menu-mobile"} ${props.theme
        } ${isOpened === true ? "menu-active" : ""}`}
    >
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">

          <a className="navbar-brand" href="/">
            {props.theme === 'theme-dark' ? (
              <img src={logoIconColor} className="logo dark" alt="appyhigh" />
            ) : (
              <img src={logoIcon} className="logo white" alt="appyhigh" />
            )}
          </a>
          <button className="navbar-toggler" type="button" onClick={toggle}>
            <div
              className={`hamburguer ${isOpened === true ? "close-hamburguer" : ""
                }`}
            >
              <div className="lines line-top"></div>
              <div className="lines line-mid"></div>
              <div className="lines line-bottom"></div>
            </div>
          </button>
          <div className={`collapse navbar-collapse `} id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink activeClassName='is-active' className="nav-link" aria-current="page" to="/aboutus">
                  About
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink activeClassName='is-active' className="nav-link" to="/product">
                  Products
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink activeClassName='is-active' className="nav-link" aria-current="page" to="/investment">
                  Investment
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink activeClassName='is-active' className="nav-link" to="/careers">
                  Careers
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink activeClassName='is-active' className="nav-link" to="/contact">
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
