import HelmetCustom from "../../components/helmetCustom";
// Custom Components
import Ecosystem from "../../components/home/ecosystem";
import IntroFirst from "../../components/home/introFirst";
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import JoinUs from "../../components/joinUs";
import WhatMakes from "../../components/home/whatMakes";
import BuildProducts from "../../components/home/buildProducts";
import FuellingImagination from "../../components/home/fuellingImagination";

// Styles
import "./styles.scss";

function Home() {
  return (
    <>
      <HelmetCustom
        title="Welcome to AppyHigh"
        metaDescription="Imagine a world where living is simple, decluttered and enriching. We are creating that reality, one app at a time!"
      />
      <div className="home-page-container">
        <Navbar />

        <section>
          <IntroFirst />
        </section>

        <section>
          <FuellingImagination />
        </section>

        <section>
          <Ecosystem />
        </section>

        <section>
          <BuildProducts />
        </section>

        <section>
          <WhatMakes />
        </section>

        <section>
          <JoinUs href="/careers" btnName={"Join Us"}>
            <h4 className="title">
              Join our team of audacious <span>dreamers</span> and{" "}
              <span>achievers</span>
            </h4>
          </JoinUs>
        </section>

        <section>
          <Footer />
        </section>
      </div>
    </>
  );
}

export default Home;
