import ButtonDark from "../../buttons/buttonDark";
import Flickity from "react-flickity-component";
import "flickity-as-nav-for";
import "flickity-fade";

// Images
import venus from "../../../assets/images/aboutus/team/investmentteam/VENUS.png";
import AKSHIT from "../../../assets/images/aboutus/team/investmentteam/AKSHIT.png";
import ANEESH from "../../../assets/images/aboutus/team/investmentteam/ANEESH.png";
import VASANT from "../../../assets/images/aboutus/team/investmentteam/VASANT.png";
import ankitkemka from "../../../assets/images/aboutus/team/investmentteam/AnkitKhemka.png";

// Styles
import "./styles.scss";

function MeetTeam(props) {
  const flickityOptions = {
    wrapAround: false,
    pageDots: false,
    prevNextButtons: true,
    contain: true,
    lazyLoad: 1,
  };

  const testimonialFlickityOptions = {
    asNavFor: ".carousel.carousel-main",
    initialIndex: 0,
    wrapAround: false,
    pageDots: false,
    prevNextButtons: false,
    lazyLoad: 1,
    fade: true,
  };

  const teamDetails = [
    {
      imgUrl: venus,
      title: "Venus Dhuria",
      desgination: "Co-Founder",
      link: 'https://www.linkedin.com/in/venusdhuria188/'
    },
    {
      imgUrl: ANEESH,
      title: "Aneesh Rayancha",
      desgination: "Co-Founder",
      link: 'https://www.linkedin.com/in/aneeshrayan/'
    },
    {
      imgUrl: AKSHIT,
      title: "Akshit Raja",
      desgination: "Head of Data Science & AI",
      link: 'https://www.linkedin.com/in/akshitraja/'
    },
    {
      imgUrl: VASANT,
      title: "Vasant Rao",
      desgination: "Advisor",
      link: 'https://www.linkedin.com/in/vasant-rao-93403667/'
    },
    {
      imgUrl: ankitkemka,
      title: "Ankit Khemka",
      desgination: "Head of Finance",
      link: 'https://www.linkedin.com/in/ca-ankit-khemka-7907b946/'
    }
  ];
  return (
    <div className={`meet-team-style ${props.classContainer}`}>
      <div className="container">
        <div className="row">
          <div className="col-sm-7">
            <div className="info-box">
              <div className="text-gradient">THE TEAM</div>
              <h4 className="title">Meet the Team</h4>
              <div className="description">
                We're entreprenuers, just like you. A thoughtfully assembled cast connected by the DNA of listening hard, learning fast and the passion for seeing our vision through to the end, no matter what the obstacle.
              </div>
              {/* <ButtonDark color="black" title="View more" /> */}
            </div>
          </div>
          <div className="col-sm-5">
            <div className="slider-primary">
              <Flickity
                className={`carousel outline-none slider-main ${props.className}`}
                elementType={"div"}
                options={testimonialFlickityOptions}
                disableImagesLoaded={false}
                reloadOnUpdate
              >
                {teamDetails.map((data, index) => {
                  return (
                    <div className="content-section" key={index}>
                      <div
                        className={`img-style`}
                      >
                        <img src={data.imgUrl} className="img-mask" alt="image mask"  />
                      </div>
                      <div className="content-box">
                        <h5 className="title5"> <a href={data.link} target="_blank">{data.title}</a></h5>
                        <p className="text-lead mb--10">{data.desgination}</p>
                      </div>
                    </div>
                  );
                })}
              </Flickity>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="slider-secondary">
              <Flickity
                className={`carousel carousel-main outline-none slider-thumb ${props.className}`}
                elementType={"div"}
                options={flickityOptions}
                disableImagesLoaded={false}
                reloadOnUpdate
              // static
              >
                {teamDetails.map((data, index) => {
                  return (
                    <div className="image-thumb-box" key={index}>
                      <div className="img-box">
                        <img src={data.imgUrl} className="img-small" alt="image thumb"  />
                      </div>
                      <div className="info-text">
                        <h5 className="title5"><a href={data.link} target="_blank"> {data.title}</a></h5>
                        <p className="text-lead mb--10">{data.desgination}</p>
                      </div>
                    </div>
                  );
                })}
              </Flickity>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MeetTeam;
