// Images
import visionbg from "../../../assets/images/aboutus/visionbg.png";

// styles
import "./vision.scss";

const VisionSec = (props) => {
  return (
    <div className="vision_sec">
      <div className="box_area">
        <div className="visionbox_parent">
          <div className="container">
            <div className="row">
              <div className="item-box col-sm-4">
                <div className="title">Our Vision</div>
                <div className="content">
                We envision a world where living is simple, decluttered and 
                enriching. We are creating that reality, one product at a time!
                </div>
              </div>
              <div className="item-box col-sm-4">
                <div className="title">Our Identity</div>
                <div className="content">
                An internet tech company the builds global 
                products to engage & inspiring millions.
                </div>
              </div>
              <div className="item-box col-sm-4">
                <div className="title">Our Purpose </div>
                <div className="content">
                We're mitigating friction in everyday lives, fostering accessibility to cutting edge technology & enabling 
                enhanced productivity to save precious time.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisionSec;
