import { Link, useHistory } from "react-router-dom";

// Images
import logo from "../../../assets/images/logo/appyhighF.svg";
import hero from "../../../assets/images/jobdetails/heroimage.png";
import detail1 from "../../../assets/images/jobdetails/detail1.jpg";
import detail2 from "../../../assets/images/jobdetails/detail2.jpg";
import detail3 from "../../../assets/images/jobdetails/detail3.jpg";
import detail4 from "../../../assets/images/jobdetails/detail4.jpg";
import detail5 from "../../../assets/images/jobdetails/detail5.jpg";
import detail6 from "../../../assets/images/jobdetails/detail6.jpg";
import detail7 from "../../../assets/images/jobdetails/detail7.jpg";
import detail8 from "../../../assets/images/jobdetails/detail8.jpg";
import detail9 from "../../../assets/images/jobdetails/detail9.jpg";
import leftArrow from "../../../assets/icons/arrow.svg"

// styles
import "./herojobdetail.scss";


const HeroSec = (props) => {
  const history = useHistory();

  return (
    <div
      className="jobdetailhero">
      <div className="container">
        <div className="header">
          <a href="/">
            <img src={logo} alt="appyhigh" />
          </a>
        </div>
        <button className="back-btn-style" onClick={() => { history.goBack() }}><img src={leftArrow} /> <span>Back </span></button>
        <div className="headertitlebox">
          <div className="titlesec">
            <div className="title">
              CURRENT OPENING
            </div>
            <div className="subtitlesec">
              {props.data ? <>
                {props.data.title}</> : null}
            </div>
          </div>
          <a href="https://forms.gle/9VrJV3juJAxPHgUB6" target="_blank" className="button">
            Apply
          </a>
        </div>
        <div className="herosecimagebox">
          {props.data.tag == 'Data_AI' ?
            <img src={detail1} alt="appyhigh tag image" />
            : null}
          {props.data.tag == 'Finance' ?
            <img src={detail2} alt="appyhigh tag image" />
            : null}
          {props.data.tag == 'Graphic_Design' ?
            <img src={detail3} alt="appyhigh tag image" />
            : null}
          {props.data.tag == 'Human_Resources' ?
            <img src={detail4} alt="appyhigh tag image" />
            : null}
          {props.data.tag == 'Marketing' ?
            <img src={detail5} alt="appyhigh tag image" />
            : null}
          {props.data.tag == 'Product' ?
            <img src={detail6} alt="appyhigh tag image" />
            : null}
          {props.data.tag == 'Technology' ?
            <img src={detail7} alt="appyhigh tag image" />
            : null}
          {props.data.tag == 'UI_UX_Design' ?
            <img src={detail8} alt="appyhigh tag image" />
            : null}
        </div>
      </div>
    </div>

  );
};

export default HeroSec;
