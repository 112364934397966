import { Link } from "react-router-dom";
// styles
import "./styles.scss";

const BrandColors = (props) => {
  return (
    <div className="combined-section">
      <div className="heading-section">
        <div className="title-lg">Brand Colors</div>
      </div>
      <div className="primary-color-container">
        <div className="primary-color-text">
          <p className="primary-color-heading">Primary Color</p>
          <p className="primary-color-description">
            White must be used as a primary color for all backgrounds to
            maintain a simple and clean look and to make sure our content
            shines. In some special cases black share may be used in the
            background to highlight special features or content.{" "}
          </p>
        </div>
        <div className="primary-color-shades">
          <div className="white-color">
            <p>
              White <br /> RGB: 255/255/255 <br /> HEX: FFFFFF <br /> CMYK:
              0/0/0/0 <br /> Pantone: White
            </p>
          </div>
          <div className="light-grey-color"></div>
          <div className="grey-color"></div>
          <div className="dark-grey-color"></div>
          <div className="black-color"></div>
        </div>
      </div>
      <div className="secondary-color-container">
        <div className="primary-color-text">
          <p className="primary-color-heading">Secondary Color</p>
          <p className="primary-color-description">
            For our accents and secondary colors we will use various gradient
            combinations to showcase the range of out products
          </p>
        </div>
        <div className="secondary-color-shades">
          <div className="secondary-color-shade secondary-color-1">
            <span className="secondary-color-shade-name-1"></span>
            <span className="secondary-color-shade-name-2"></span>
          </div>
          <div className="secondary-color-shade secondary-color-2">
          <span className="secondary-color-shade-name-1"></span>
            <span className="secondary-color-shade-name-2"></span>
          </div>
          <div className="secondary-color-shade secondary-color-3">
          <span className="secondary-color-shade-name-1"></span>
            <span className="secondary-color-shade-name-2"></span>
          </div>
          <div className="secondary-color-shade secondary-color-4">
          <span className="secondary-color-shade-name-1"></span>
            <span className="secondary-color-shade-name-2"></span>
          </div>
          <div className="secondary-color-shade secondary-color-5">
          <span className="secondary-color-shade-name-1"></span>
            <span className="secondary-color-shade-name-2"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandColors;
