import React, { useState } from "react";

// Styles
import "./Team.scss";

const Team = (props) => {
  const media = window.matchMedia(`(min-width: 768px)`);
  const [showMore, setShowMore] = useState(false);
  const numberOfItems = showMore ? props.teams.length : media.matches === true ? 12 : 100;

  return (
    <div className="teamparent">
      <div className="team_inner">
        <div className="titlearea">
          <div className="title">Meet the team</div>
          <div className="text">
            Working at AppyHigh feels awesome. We have a culture of trailblazing and we have a lot of fun while working our tails off. Every member of our team has an entrepreneurial itch and looks to make great impact at AppyHigh.
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="teammember_box" id="Teams">
              {props.teams.slice(0, numberOfItems).map((item) =>
                <div className="col-6 col-sm-3 padding-sm">
                  <div className="imagearea">
                    <img src={item.image} alt="team image" />
                  </div>
                  <div className="textarea">
                    <div className="name">{item.name}</div>
                    <div className="designation">{item.designation}</div>
                  </div>
                </div>
              )
              }
            </div>
            <div className="showmorebtn" onClick={() => setShowMore(!showMore)}>
              Show {showMore ? "Less" : "More"}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Team;
