import { useEffect } from "react";

// images
import scroll_mouse_icon from "../../../assets/images/investment-page/scroll-mouse-icon.svg";
import videoParticle from "../../../assets/videos/particle.mp4";

// styles
import "./styles.scss";

const TopBanner = (props) => {
  useEffect(() => {
    var vid = document.querySelector(".video-banner");
    vid.className = "video-banner loading";
    document
      .getElementById("team-video")
      .addEventListener("loadstart", () => { });
    document
      .getElementById("team-video")
      .addEventListener("canplaythrough", () => {
        vid.className = "video-banner";
      });
  }, []);

  return (
    <div
      className="container-fluid top-banner"
    // style={{backgroundImage: `url("${bannerBg}")`}}
    >
      <div className="video-banner">
        <video
          playsInline
          autoPlay
          muted
          loop
          id="team-video"
        >
          <source src={videoParticle} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="container" style={{ color: props.textColor }}>
        <div className="text-section">
          <div className="pagetitle">Investment</div>
          <h1 className="headtitle">
            We back early-stage <br />startups  with capital & heart
          </h1>
          <div className="headcontent">
            A Top-Tier, pre-seed fund helping exceptional Indian startups and
            the bold leaders behind them go much further, much faster
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBanner;
