import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import Lottie from "react-lottie";
import shape1 from "../../../assets/images/json/webuild.json";
import shape2 from "../../../assets/images/json/welaunch.json";
import shape3 from "../../../assets/images/json/wegrow.json";

// styles
import "./whowe.scss";

const Whowe = (props) => {
  const settings = {
    dots: false,
    speed: 500,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          variableWidth: false,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 5000
        },
      },
    ],
  };
  return (
    <div className="container-fluid whowe" id="whoweSection">
      <div className="innerwhowe">
        <Slider {...settings}>
          <div>
            <div>
              <div className="imgbox">
                <Lottie
                  options={{
                    animationData: shape1,
                    loop: true,
                    autoplay: true,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                />
              </div>
              <div className="contentbox">
                <div className="title">We build</div>
                <div className="cont">
                  We create beautiful prototypes and validate them with MVPs to
                  come up with apt products for the new-age minds.
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div className="imgbox">
                <Lottie
                  options={{
                    animationData: shape2,
                    loop: true,
                    autoplay: true,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                />
              </div>
              <div className="contentbox">
                <div className="title">We launch</div>
                <div className="cont">
                  Efficient go-to-market strategies juxtaposed with rightful
                  skills & resources is what we build upon.
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div className="imgbox">
                <Lottie
                  options={{
                    animationData: shape3,
                    loop: true,
                    autoplay: true,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                />
              </div>
              <div className="contentbox">
                <div className="title">We grow</div>
                <div className="cont">
                  We’ve made the journey from ideation to traction multiple
                  times - The climb doesn’t kill us anymore.
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default Whowe;
